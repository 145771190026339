$color-base: rgb(231, 231, 231);
$color-highlight: lighten($color-base, 3%);

.skeleton {
  position: relative;
  overflow: hidden;
  background: $color-base;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, $color-base, $color-highlight, $color-base);
    animation: progress 1s ease-in-out infinite;
  }
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

// skeleton 2
.skeleton2 {
    padding: 15px;
    .skeleton-banner {
        width: 100%;
        min-height:  200px;
    }
}


// skeleton 3

.skeleton3 {
    padding: 15px;
}

.skeleton-bar {
    width: 100%;
    height: 300px;
}

.skeleton-short-bar {
    width: 70%;
    margin: 10px 0;
    height: 20px;
}

// skeleton 1

.skeleton1 {
    padding: 15px;
    display: inline-block;
    width: 100%;
    .skeleton1-inner{
            :after {
            clear:  both;
            display:  block;
            content: '';
        }
        .skeleton1-left {
            width: 37%;
            float: left;
            margin-right: 15px;
            .skeleton-img {
                height: 150px;
                width: 100%;
            }
        }
        .skeleton1-right {
            width: 55%;
            float: left;
            .skeleton-bar {
                margin: 22px 0;
            }
        }
    }
}

// skeleton 4
.skeleton4 {
    height: 80px;
    padding: 10px 15px;
    .skeleton-4-cont {
        display:  inline-block;
        width: 28%;
        margin-right: 10px;
    }
    
    .skeleton-img.skeleton {
        height: 70px;
        width: 50px;
    }
    
    .skeleton-4-left {
        float: left;
    }
    
    .skeleton-4-right {
        float: left;
        width: 30px;
        padding: 11px 4px;
    }
    .skeleton-bar {
        height: 14px;
    }
    
    .skeleton-short-bar {
        height: 14px;
    }
}

// desktop slider skeleton
.slider-skeleton {
    display: block;
    max-width: 1500px;
    padding: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    vertical-align: top;
    margin: 0 auto;

    .slider-skeleton-left {
        float: left;
        width: 75%;
        height: 300px;
        overflow: hidden;
        background: #ddd;
        &>div {
            height: 100%;
        }
    }
    
    .slider-skeleton-right {
        float:  left;
        width: 25%;
        display: block;
        padding-left: 15px;
        &> div {
            margin-bottom: 15px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 142.5px;
            overflow: hidden;
        }
    }
}

// variant skeleton
.variant-skeleton-container {
    float: none;
    margin: 0 auto;
    clear: both;
    width: 100%;
    max-width: 960px;
    min-width: 960px;
    width: 100%;
    max-width: 1170px;
    .variant-head-skeleton {
        width: 400px;
        margin: 40px auto 20px;
        height: 32px;
    }
    .skeleton-bar {
        width:  100%;
        height: 100%;
    }
}

.variant-item-container-skeleton{
    display: inline-block;
    width: 100%;
}
.variant-item-skeleton{
    float: left;
    width: 25%;
    padding: 0 30px;
}
.variant-item-img-skeleton {
    height:  160px;
    width:  160px;
    margin: 20px auto 20px;
}

.variant-item-content-skeleton > div {
    display: inline-block;
    height: 20px !important;
    width: 100%;
}

//title
.skeleton-title {
    width: 25%;
    margin: 30px;
    height: 30px;
}

.skeleton-sub-title {
    width: 35%;
    margin: 30px;
    height: 30px;
}

// cart item skeleton

.cart-skeleton-container {
    float: none;
    margin: 0 auto;
    clear: both;
    width: 100%;
    max-width: 960px;
    min-width: 960px;
    width: 100%;
    max-width: 1170px;
    .cart-head-skeleton {
        width: 400px;
        margin: 40px auto 20px;
        height: 32px;
    }
    .skeleton-bar {
        width:  100%;
        height: 100%;
    }
}
.cart-item-container-skeleton{
    display: inline-block;
    //width: 100%;
    height:  276px;
    width:  325px;
}
.cart-item-skeleton{
    float: left;
    width: 25%;
    //padding: 0 50px;
}
.cart-item-img-skeleton {
    height:  145px;
    width:  276px;
    margin: 20px auto 20px;
}

.cart-item-content-skeleton > div {
    display: inline-block;
    height: 20px !important;
    margin: 0px 9px 10px;
}

.skeleton-button {
    width: 85%;
    margin: 10px 14px;
    height: 20px;
}

// category page skeleton
.span4{
    .skeleton{
        min-height: 600px;
    }
}
.span12{
    .slider-skeleton{
        display: inline-block;
        max-width: 1500px;
        padding: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        vertical-align: top;
        margin: 0 auto;
    }
}

// mobile listing skeleton style
.mob-list-skeleton{
    .variant-item-skeleton {
        width: 90%;
        padding: 15px 15px;
        margin: 20px 0;
        border: 1px solid #f2f2f2;
        margin: 20px 20px;
        border-radius: 4px;
    }
    
    .variant-item-img-skeleton {
        width:  95px;
        height: 95px;
        float:  left;
        margin: 0;
        border-radius: 4px;
    }
    
    .variant-item-img-skeleton > div {
        width:  100%;
        height: 100%;
        border-radius: 4px;
    }
    
    .variant-item-content-skeleton {
        float:  left;
        width: calc(100% - 95px);
        padding: 10px 15px 15px 15px;
        border-radius: 4px;
    }

    .variant-item-button-skeleton > div {
        display: inline-block;
        height: 20px !important;
        width: 100%;
    }
    
}

.skeleton-variant-short-bar {
    width: 70%;
    //margin: 10px 0;
    //height: 20px;
    border-radius: 4px;
}

.skeleton-variant-button {
    width: 47% !important;
    margin: 0 5px 0 0;
    height: 20px;
    border-radius: 4px;
}

.skeleton-button {
    width: 30%;
    height: 20px;
}
.page-layout-desktop {
    .skeleton-pattern {
        .span4{
            width: 30%;
            height: 600px;
            float: left;
        }
        .span12{
            width: 65%;
            height: 800px;
            float: left;
            margin-left: 20px;
            .skeleton{
                width: 100%;
                height: 100%;
            }
        }
    }
}

// Home page banner skeleton
.home-page-banner-skeleton {
    .skeleton-banner {
        width: 100%;
        min-height:  632.5px;
    }
}

.header-top-revamp-menu-parent-skeleton{
    padding: 0px 0px;
    @media screen and (max-width: 1024px) {
        padding: 0px 16px;
    }
    .header-top-revamp-menu-section-first-skeleton{
        left: 160px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0px 15px;
        position: relative;
        margin: 22px 40px 0px 40px ;
        width:70%;
        .header-top-revamp-section-items-skeleton{
            margin-right: 68px;
            width: 10%;
            margin: 10px 0;
            line-height: 60px;
            height:22px;
            align-items: center;
        }
    }
}

.popular-item-skeleton{
    float: left;
    padding-left: 100px;
    width: 20%;
}

.popular-item-img-skeleton {
    height:  218px;
    width:  178px;
    margin: 20px auto 20px;
}