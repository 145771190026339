@import 'static/css/global/global.scss';
* {
    font-family: $fontPoppins;
    font-size: $fontSize-default;
    color: $fontColor-default;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}
.modal-open{
  overflow: hidden;
}
html,
body,
#__next,
.hk-app,
.hk-app>.main-container-wrapper {
  min-height: 100%;
  min-height: 100vh;
}
.main-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .react-page-container {
    flex: 1 1 auto;
    min-height: calc(100vh - 76px);
    @media screen and (max-width:1023px) {
        min-height: calc(50vh - 76px);
    }
  }
}
body {
    font-family: $fontPoppins;
    font-size: $fontSize-default;
    line-height: 21px;
    color: $fontColor-default;
    font-weight: normal;
    overflow-x: hidden;
    width: 100%;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

p {
    margin: 0;
}

/*
==========================================================================================
                        C O M M O N     S T Y L I N G
==========================================================================================
*/
text.highcharts-credits {
    display: none;
}

select {
    -webkit-appearance: none;
}

.inputBoxField,
.textAreaBoxField {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 15px 15px;
    position: relative;
    height: 58px;

    button {
        position: absolute;
        right: 13px;
        z-index: 11;
        top: 12px;
        height: 30px;
        width: auto;
        border: none;
        outline: none;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;
    }

    label {
        display: block;
        font-size: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: calc(100% - 32px);
        color: rgba(0, 0, 0, 0.38);
        z-index: 10;
        
    }

    &.dateField {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/Gritzo/AskAnExpert/calendarIcon.svg);
        background-repeat: no-repeat;
        background-position: 95% 50%;

        input[type=date] {
            position: relative;

            &::-webkit-clear-button {
                display: none;
            }

            &::-webkit-inner-spin-button {
                display: none;
            }

            &::-webkit-calendar-picker-indicator {
                font-size: 18px;
                color: red;
                width: 100%;
                position: absolute;
                height: 100%;
                opacity: 0;
            }
        }
    }

    input,
    select {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: calc(100% - 32px) !important;
        border: none;
        z-index: 11;
        line-height: 19px;
        outline: none;
        padding: 0 !important;
        border: 0 !important;
        height: auto !important;
        background: transparent !important;
        
        // -webkit-appearance: none !important;
    }

    &.focusing {
        label {
            font-size: 14px;
            width: auto;
            top: -11px;
            left: 16px;
            transform: none;
            background: #fff;
            padding: 0 5px;
        }
    }

    textarea {
        width: 100%;
        border: none;
        z-index: 11;
        line-height: 19px;
        outline: none;
        padding: 0;
        border: 0;
        background: transparent;
        resize: none;
        
    }
}

.textAreaBoxField {
    height: initial;

    label {
        top: 15px;
        left: 15px;
        transform: none;
    }
}

.success-txt {
    color: $green-1;
    margin: 0;
}

.err-txt {
    color: $error-color;
}

.inputBox {
    &.error {
        

        .inputBoxField {
            border-color: $error-color;

            label {
                color: $error-color;
                margin: 0;
            }
        }
    }

    .err-txt {
        color: $error-color;
        margin: 0;
    }
}

.textAreaBox {
    &.error {
        .textAreaBoxField {
            border-color: $error-color !important;

            label {
                color: $error-color;
                margin: 0;
            }
        }
    }

    .err-txt {
        color: $error-color;
        margin: 0;
    }
}

.lineThrough {
    color: #A0A0A0;
    text-decoration: line-through;
}

.arrow {
    border-right: 2px solid #000;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-bottom: 2px solid #000;
    margin: 0 5px;

    &.down {
        transform: rotate(45deg);
    }

    &.up {
        border-right: 0;
        border-bottom: 0;
        border-top: 2px solid #000;
        border-left: 2px solid #000;
        transform: rotate(45deg);
    }
}

.tbs-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    z-index: 9999;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 999999;
}

.tbs-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #ffffff85;
    z-index: 999999;

    svg {
        width: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999999;
    }
}

.tbs-toast {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 400px;
    background: $green-1;
    z-index: 999999;
    padding: 5px 20px;
    box-shadow: 1px 2px 3px $grey-11;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    opacity: 1;
    text-align: center;
    //  -webkit-transition: opacity 2s ease-in-out;
    //  -moz-transition: opacity 1s ease-in-out;
    //  -ms-transition: opacity 1s ease-in-out;
    //  -o-transition: opacity 1s ease-in-out;
    //  transition: opacity 1s linear;
    animation: tbs-toast-anim .5s linear;

    .tbs-toast-message {
        color: #ffffff;
        
        text-align: center;
    }

    &.error-toast {
        background: $error-color;
        ;
    }

    &.closing-toast {
        // left: 50%;
        opacity: 1;
        animation: tbs-toast-close .6s linear;
    }
}

.tbs-confirm-popup {
    @include widthFloat();

    .pu-box {
        width: 80%;
        max-width: 300px;
        background: #ffffff;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        z-index: 999999;

        .pu-footer-btns {
            @include widthFloat();
            border-top: 1px solid $grey-11;
            text-align: center;

            span {
                width: 50%;
                float: left;
                padding: 17px;
                line-height: 13px;
                border-right: 1px solid $grey-11;
                color: #505050;
                
                cursor: pointer;

                &:last-child {
                    border: none;
                }
            }

            .pu-footer-btn-del {
                color: $primary-color;
            }
        }
    }
}

.hk_logo_header {
    .a {
        fill: #003d78 !important;
    }

    .b {
        fill: url(#a) !important;
    }
}

.no-scrolling {
    overflow: hidden;
}

//   default pagesection
.pgSecCont {
    display: inline-block;
    width: 100%;

    .defaultPgSecItemComp {
        padding: 20px 15px;

        &>h2 {
            @include headingDefaultStyle($fontSize:24px, $lineHeight:32px, $fontWeight:700, $color:$fontColor-default);
            margin-bottom: 15px;
        }

        iframe {
            width: 100%;
        }

        .pgSecContItem {
            margin-bottom: 20px;

            &:last-child {
                margin: 0;
            }

            img {
                width: 100%;
                margin: 0 0 15px;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin: 0;
                text-align: left;
            }

            h1 {
                font-size: 18px;
                line-height: 23px;
                font-weight: 600;
                
                margin-bottom: 10px;
            }

            h2 {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                
                margin-bottom: 10px;
            }
        }
    }
}
.fixed-cont-img{
    height: 403px;
}
// CUSTOM SLIDER STYLING
.slider-outer {
    position: relative;
    width: 90px;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    height: 100%;
    max-height: 370px;
    @media screen and (max-width:767px) {
        width: 58px;
        max-height: 273px;
        margin-top: -9px;
    }
    .zoom_left,
  .zoom_right {
    cursor: pointer;
    position: absolute;
    box-shadow: 0px 1px 3px #00000029;
    text-align: center;
    background: rgba(64, 64, 65, 0.70);
    height: 20px;

    &.invert {
      img {
        transform: rotate(180deg) !important;
      }
    }

    img {
      width: 22px;
    }
  }

  .zoom_left {
    top: 0;
    left: 0;
    right: 0;
  }

  .zoom_right {
    left: 0;
    right: 0;
    bottom: 0;
  }

    span img {
        height: 18px;
    }

    .gallery {
        overflow: hidden;
        width: auto;
    }

    .image_container {
        position: relative;
        // min-width: 1500px;
        white-space: normal;
        font-size: 0;
        text-align: left;
        padding: 20px 0;
    }

    .image_container .slide-item {
        display: inline-block;
        border: 1px solid #d3d4d5;
        box-sizing: border-box;
        box-shadow: $card-material-box-shadow;
        overflow: hidden;
        cursor: pointer;
        max-height: 90px;
        max-width: 90px;

        &.active {
            border: solid #00bfbf 1px;
        }
    }

    .slide-item img {
        width: 100%;
        height: 100%;
        margin: 0 !important;
    }
}

/*
==========================================================================================
                        M O B I L E     S T Y L I N G
==========================================================================================
*/

// plp card box
.plpCardComp {
    padding: 25px 20px;
    padding-left: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: $card-material-box-shadow;
    background: #fff;

    .sold-out-vrnt {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff9c;

        span {
            
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            display: inline-block;
            border: 1px solid #000;
            padding: 10px;
            border-radius: 4px;
        }
    }

    .plpCardComp__inner {
        display: flex;
        justify-content: space-between;
        position: relative;

        &>a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .plpCardComp__imgBox img {
            width: 100%;
            height: auto;
        }

        .plpCardComp__imgBox {
            max-width: 95px;
        }

        .plpCardComp__detail {
            width: calc(100% - 110px);
        }

        .plpCardComp__detail__name {
            @include headingDefaultStyle($fontSize:14px, $fontWeight:800, $lineHeight:19px, $color:#000);
        }

        .plpCardComp__detail__flv {
            font-size: 12px;
            color: #797979;
            line-height: 14px;
        }

        .plpCardComp__detail__for {
            margin: 0;
        }

        .plpCardComp__detail__for__age {
            @include headingDefaultStyle($fontSize:14px, $fontWeight:800, $lineHeight:19px, $color:#000);
            margin-bottom: 10px;
        }

        .product__attributes {
            display: flex;
            margin-top: 17px;
            margin-bottom: 20px;

            .item-img {
                margin-right: 20px;
            }

            img {
                max-height: 25px;
            }
        }

        span.plpCardComp__detail__price__offrPr {
            font-size: 18px;
            margin-right: 11px;
            font-weight: 700;
            line-height: 22px;
        }
    }
}

.mobile-view-wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background: #fff;
    min-height: 100vh;
}

.back-arrow-header {
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background: #ffffff;
    z-index: 9999999;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);

    &.show-border {
        border-bottom: 1px solid #ddd;
    }

    img {
        position: absolute;
        width: 16px;
        height: 16px;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }

    .heading {
        position: absolute;
        left: 50px;
        top: 50%;
        
        font-weight: bold;
        font-size: 16px;
        color: #263238;
        transform: translateY(-50%);
    }
}

.mobile-content-padding {
    padding-top: 80px;
}
/*
==========================================================================================
                        D E S K T O P     S T Y L I N G
==========================================================================================
*/
@media screen and (min-width:1024px) {

    // header
    .main-header-wrapper .header-top .hk_logo_header {
        margin-left: -15px;
        margin-top: 35px;
    }

    .main-header-wrapper .header-top .logo-menu-wrapper.defaultHeader .hk_logo_header {
        margin-left: 0;
        margin-top: 0;
    }

    .main-header-wrapper .header-top .logo-menu-wrapper .header-flex {
        height: 70px;
    }

    .main-header-wrapper .header-top .logo-menu-wrapper {
        height: 70px;
    }

    .menu-wrapper .menu-item {
        width: 400px;
        padding-left: 78px;
        background: $light-border-color;
    }

    .desktop-cross-btn {
        text-align: right;
        padding: 10px 62px;
    }

    .toggle-menu.tr-menu-mob {
        margin-left: 30px;
    }

    .menu-wrapper .menu-item .menu-list-container {
        padding: 30px 0;

        .hk-menu-my-acc a {
            @include headingDefaultStyle ($fontSize:18px, $lineHeight:30px, $fontWeight:800, $color:$brand-color);
        }
    }

    .menu-wrapper .menu-item .menu-list-container .menu-list-container--user {
        padding-top: 0;
        margin-top: 0;
        display: inline-block;
        width: 100%;
        border-top: 0;
    }

    .menu-wrapper .menu-item .user-bar {
        display: flex;
        padding: 0;
        background: transparent;
    }


}

.no-chat-button {
    iframe.xdk-iframe:last-child {
      visibility: hidden !important;
      opacity: 0;
    }
  }
  .no-open-appbar {
    .open-appbar {
      visibility: hidden !important;
      opacity: 0;
    }
  }
  