@import './variable.scss';
// colors
$fontColor-default: #2C2C2C;
$fontColor-default-Mweb: #20262E;
$fontColor-grey5: #E6E6E6;
$fontColor-black1: #333333;
$fontColor-black2: #505050;
$fontColor-black3: #100E09;
$primary-color: #FF7E00;
$primary-color2: #FF6C00;
$brand-color: #FE7E10;
$secondary-color: #21A0B0;
$secondary-color1: #487287;
$border-color:#d3d4d5;
$orange: #FB8C00;
$light-border-color :#EFEFEF;
$sky-blue:#20ABE1;
$minor-black:#4A5E78;
// button colors
$btn-primary-default: #006CD6;
$btn-primary-active: #22a0b1;
$btn-primary-inactive: #EEEEEE;
$orange-1: #FF7300;
$orange-hover: #ff6000;
$checkout-btn-color:#FF6C0E;
$light-pink: #FFF8F9;
$white-color: #FFFFFF;
$black: #000000;
$black-1: #393939;
$blog-bg: #EAF6FF;
// image background
$img-bg: #F2F3F5;
$promo-color:#F2F2F2;
$blac-text-color:#070907;
$header-background:#080A08;
$yellow_color:#FDE100;
$black-dark:#001D42;
$light-blue_new:#001D42CC;
$black-mb:#080A08;
$light-blue-clr:#01A685;
$grey-clor:#717263;

//border-radius
$border-radius-2:2px;

// font mixin;
@mixin fontStyle($fontFamily, $size, $weight, $line-height, $letter-spacing, $color) {
    font-family: $fontFamily;
    font-size: $size;
    font-weight: $weight;
    line-height: $line-height;
    letter-spacing: $letter-spacing;
    color: $color;
}

//material design box-shadow
@mixin material-box-shadow($x, $y, $blur, $color) {
    box-shadow: $x $y $blur $color;
}

$card-material-box-shadow : 0px 2px 6px rgba(0, 0, 0, 0.16);
//truebasics page padding
$page-padding:55px;
$header-height-desktop: 70px;
$header-height-mobile: 55px;

// functions
@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transition-property($property...) {
    -moz-transition-property: $property;
    -o-transition-property: $property;
    -webkit-transition-property: $property;
    transition-property: $property;
}

@mixin transition-duration($duration...) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
    transition-delay: $delay;
}

@mixin widthFloat {
    width: 100%;
    float: left;
}

@mixin animation ($animation) {
    -webkit-animation: $animation;
    -moz-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}

@mixin keyframe($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-o-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin fadeInAnimation {
    animation-name: fade;
    animation-duration: 1s;

    @keyframes fade {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}



@mixin font-family($font-family, $weight) {
    font-family: '#{$font-family}',
    sans-serif;
    font-weight: #{$weight};
}

@mixin background-opacity($color, $opacity: 0.3) {
    background: $color;
    background: rgba($color, $opacity);
}

$max-breakpoint: 1920;
$wide-screen: "(min-width: #{$max-breakpoint}px)";

@function get-vw($target) {
    $vw-context: ($max-breakpoint * .01) * 1px;
    @return calc($target / $vw-context) * 1vw;
}

@function get-vw-1($target) {
    $vw-context: ($max-breakpoint * .15220700152207) * 1px;
    @return calc($target / $vw-context) * 1vw;
}

@mixin setProperty($property, $value) {
    #{$property}: get-vw($value);

    @media #{$wide-screen} {
        #{$property} :#{$value};
    }
}

@mixin border($color, $height: 1px, $style: solid, $opacity: 0.3) {
    border: $height $style rgba($color, $opacity);
}

@mixin border-top($color, $height: 1px, $style: solid, $opacity: 0.3) {
    border-top: $height $style rgba($color, $opacity);
}

@mixin border-bottom($color, $height: 1px, $style: solid, $opacity: 0.3) {
    border-bottom: $height $style rgba($color, $opacity);
}

@mixin border-left($color, $height: 1px, $style: solid, $opacity: 0.3) {
    border-left: $height $style rgba($color, $opacity);
}

@mixin border-right($color, $height: 1px, $style: solid, $opacity: 0.3) {
    border-right: $height $style rgba($color, $opacity);
}

@mixin setProperty-important($property, $value) {
    #{$property}: get-vw($value) !important;

    @media #{$wide-screen} {
        #{$property} :#{$value} !important;
    }
}

@mixin box-shadow($shadow) {
    -moz-box-shadow: $shadow;
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin defaultHeading () {
    font-family: $fontPoppins;
    text-transform: capitalize;
    font-size: 25px;
    line-height: 31px;
    font-weight: $semibold;

    @media screen and (max-width: 400px) {
        font-size: 18px;
        line-height: 24px;
    }
}

@mixin defaultHeadingMob () {
    font-family: $fontPoppins;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 24px;
    font-weight: $semibold;
}

@mixin defaultText () {
    font-size: 14px;
    line-height: 26px;
    font-weight: normal;
}

@mixin headingDefaultStyle ($fontSize:16px, $lineHeight:21px, $fontWeight:600, $color:$brand-color) {
    font-family: $fontPoppins;
    font-weight: $fontWeight;
    color: $color;
    font-size: $fontSize;
    line-height: $lineHeight;
    margin: 0;
}

@mixin defaultButton ($fontSize:14px, $lineHeight:47px, $height:50px, $borderColor:$primary-color, $backgroundColor:$primary-color, $fontWeight:600, $color:#fff) {
    font-size: $fontSize;
    line-height: $lineHeight;
    height: $height;
    border: 1px solid transparent;
    border-color: $borderColor;
    background-color: $backgroundColor;
    font-family: $fontPoppins;
    font-weight: $fontWeight;
    color: $color;
    border-radius: 25px;
    display: inline-block;
    padding: 0 20px;
}

@mixin roundedButton ($borderRadius:50px, $height: 49px, $borderColor: $grey-8) {
    height: $height;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $black;
}

@mixin defaultMobileHeading ($fontSize: 18px, $lineHeight:23px, $fontWeight:500, $color:$black, $fontStyle:regular) {
    font-family: $fontPoppins;
    font-weight: $fontWeight;
    font-style: $fontStyle;
    color: $color;
    font-size: $fontSize;
    line-height: $lineHeight;
    margin: 0;
}

@mixin text-overflow($lineCount: 1) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;
}

@mixin heading1() {
    text-transform: capitalize;
    font-size: 21px;
    line-height: 31px;
    font-weight: $semibold;
    text-align: left;
    color: $black;
}

.row {
    float: left;
    width: 100%
}

@mixin set-box-shadow($x, $y, $blur, $color) {
    box-shadow: $x $y $blur $color;
}

/* --------------------------------------------------
Flexbox SASS mixins
 --------------------------------------------------*/
// Flexbox display
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
    -webkit-flex-flow: $flow;
    -moz-flex-flow: $flow;
    -ms-flex-flow: $flow;
    flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
    flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number> 
@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width> 
@mixin flex-basis($width) {
    -webkit-flex-basis: $width;
    -moz-flex-basis: $width;
    -ms-flex-basis: $width;
    flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch 
@mixin align-content($align) {
    -webkit-align-content: $align;
    -moz-align-content: $align;
    -ms-align-content: $align;
    align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    -ms-flex-align: $align;
    align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch 
@mixin align-self($align) {
    -webkit-align-self: $align;
    -moz-align-self: $align;
    -ms-align-self: $align;
    align-self: $align;
}

@mixin btn-black2() {
    background: $black2;
    color: $white;
    border: 1px transparent solid;
}

@mixin btn-yellow {
    background: $yellow;
    color: $black !important;
}

@mixin btn-yellow2 {
    background: $yellow2;
}

@mixin lineClamp($line: 2) {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    /* autoprefixer: off */
}

@mixin absoluteCenter(){
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@mixin owl-nav() {
    width: 22px;
    height: 32px;
    background-position: 0 0 !important;
    background-repeat: no-repeat !important;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    background-size: contain !important;
    text-indent: -9999px;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;
    display: block !important;
    border-radius: 0 !important;
}

@mixin lineClamp($line: 2) {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    /* autoprefixer: off */
}

@mixin viewAll{
    width: fit-content;
    margin: 0 auto;
    display: block;
    text-decoration: underline;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    @include fontStyle($fontFamily-default, 20px, 400, 32px, 0px, #898989);
    @media(max-width:768px){
        @include fontStyle($fontFamily-default, 14px, 400, 32px, 0px, #898989);
    }
}

@mixin scrollbar-thin() {
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.15);
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(112, 112, 112, 0.8);
    }
}

@mixin fadeInAnimation2 {
    animation-name    : fade2;
    animation-duration: 1s;

    @keyframes fade2 {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}