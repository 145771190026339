@import '../global/global.scss';

.select-payment {
    @media screen and (min-width: 1024px) {
        flex-direction: row !important;
    }

    .order-summary {
        .apply-offer__container {
            margin-left: 0 !important;
            margin-right: 18px;
        }
    }
}

.checkout-flow {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    min-height: calc(100vh - 122px);

    @media screen and (min-width: 1024px) {
        &.express-cls {
            flex-direction: row-reverse;
        }
    }

    .checkout-flow-all-offers {
        margin-left: 35px;
        margin-top: -20px;
    }

    .checkout-flow-apply-offers {
        margin-left: 35px;
    }

    .order-summery-express-checkout-1 {
        border-left: 1px solid rgba(27, 40, 92, 20%);
        margin-top: 60px;
    }

    .order-summary {
        width: 33%;
        //background-color: #F2F3F5;
        background-color: #fff;

        .pdp-accordion {
            background-color: #F2F3F5;
            margin-top: 20px;
            margin-right: 18px;

            .pdp-accordion__header {
                border-bottom: none;

                .pdp-accordion__title {
                    padding-left: 20px;

                    @media screen and (min-width:768px) {
                        font-size: 20px;
                    }
                }

                img {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }

        .coupn-apply__container {
            padding: 0px 0px 15px 31px;
            background-color: #F2F3F5;


            @media screen and (min-width: 1024px) {
                background-color: #FFF;
                padding-left: 0px;
                box-shadow: none !important;
                border-top: 1px solid #1B285C !important;
                padding-top: 30px;

            }

            .invalid-coupon {
                color: #ED0000;
                display: flex;
                gap: 50px;
                cursor: pointer;

                p {
                    img {
                        position: relative;
                        right: 32px;
                        top: 6px;
                    }
                }
            }

            .coupon-data {
                display: flex;
                flex-direction: row;
                border: 1px dashed;
                margin: 10px 0px 10px 0px;
                padding: 5px 10px 5px 10px;
                justify-content: space-between;

                .coupons-item {
                    display: flex;
                    flex-direction: column;

                    .coupon-value {
                        font-weight: 600;
                    }

                    .coupon-name {
                        span {
                            color: #01A685;
                        }
                    }
                }

                p {
                    margin-right: 10px;
                    cursor: pointer;
                }
            }
        }

        .apply-offer__container {
            padding: 2px 0 0 12px;
            margin-left: 31px;

            .atc {

                border-radius: 5px;
                border: 1px solid;
                /* width: 100px; */
                padding: 0px 33px;

                @media screen and (max-width: 1024px) {
                    position: absolute;
                    bottom: 13px;
                    margin-top: 25px;
                }

                @media screen and (min-width: 1024px) {
                    border: none;
                    padding: 0px;
                }
            }
        }

        .checkout-flow-mb-cash {
            margin-left: 35px;
        }

        .order-sum__container-payment {
            background-color: #FFF !important;
            padding: 0px 0px 0px 0px !important;

            @media screen and (min-width: 1024px) {
                padding: 0px 16px 0px 0px !important;

            }

            h1 {
                @include fontStyle($font-family-barlow, 16px, 500, 20px, 0px, #20262E);
                margin-top: 25px;
                margin-bottom: 15px;
                border-bottom: 1px solid #E2E2E2;
                padding-bottom: 15px;

            }
        }

        .order-summery-express-checkout {
            @media screen and (min-width : 1024px) {
                border-right: none !important;

            }
        }

        .order-summery-express-checkout {
            margin-top: 0px !important;
            margin-bottom: 0px;

            @media screen and (max-width: 1024px) {
                padding: 0px !important;
            }

        }

        .order-sum__container-payment,
        .order-sum__container {
            padding: 37px 31px 40px 41px;
            background: inherit;
            background-color: #FFF;
            margin-top: 30PX;

            @media screen and (min-width : 1024px) {
                background-color: #FFF;
                border-bottom: none;
                border-right: 1px solid #F1F3F6;
                margin-top: 60px;
                padding: 0px 31px 40px 41px;
            }

            .deliver_to {
                margin-top: 37px;
                padding-bottom: 27px;
                border-bottom: 1px solid #E2E2E2;
                display: inline-block;
                justify-content: space-between;
                align-items: end;
                position: relative;

                .deliver {
                    @include fontStyle($fontFamily-default, 18px, 600, 26px, 0px, #080A08);
                    padding-bottom: 10px;
                }

                .deliver_action {
                    position: absolute;
                    top: -6px;
                    right: 0px;
                }

                .location {
                    @media screen and (min-width:768px) {
                        display: inline-block;
                        justify-content: center;
                        align-items: center;
                        width: 80%;

                        .usrname {
                            @include fontStyle($fontFamily-default, 16px, 600, 21px, 0px, #080A08);
                        }
                    }

                    img {
                        margin-right: 10px;
                        vertical-align: middle;
                    }

                    p {
                        @include fontStyle($font-family-barlow, 14px, $bold, 18px, 0px, $header-background);

                        &.last {
                            padding: 8px 0 0 28px;

                            @media screen and (min-width:768px) {
                                padding: 0px 0 0 4px;
                            }

                        }
                    }
                }

                .deliver_action {
                    .change {
                        @include fontStyle($font-family-barlow, 14px, 600, 18px, 0px, #4192DF);
                        // text-decoration: underline;
                        vertical-align: bottom;
                        text-transform: uppercase;
                    }
                }

                @media screen and (max-width: 767px) {
                    margin-top: 15px;
                    padding-bottom: 9px;
                    align-items: center;

                    .deliver {
                        @include fontStyle($fontFamily-default, 16px, 600, 22px, 0px, #080A08);
                        padding-bottom: 8px;
                    }

                    .location {
                        .usrname {
                            @include fontStyle($fontFamily-default, 16px, 600, 21px, 0px, #080A08);
                            padding-bottom: 8px;
                        }

                        .address-line {
                            @include fontStyle($fontFamily-default, 14px, 400, 20px, 0px, #080A08);

                        }

                        p {
                            &.last {
                                padding: 3px 0 0 28px;
                            }
                        }
                    }

                    .deliver_action {
                        .change {
                            @include fontStyle($font-family-barlow, 13px, $medium, 18px, 0px, $header-background);
                        }
                    }
                }
            }

            .order-sum {
                &__items {
                    row-gap: 10px;
                    margin-top: 27px;

                    @media screen and (max-width: 767px) {
                        margin-top: 9px;
                    }
                }

                &__item {

                    h6,
                    h4 {
                        @include fontStyle($fontFamily-default, 16px, 500, 20px, 0px, #20262E);

                    }

                    .tot-dis {
                        color: #51B279;
                    }

                    &.total {
                        margin-top: 24px;
                        padding: 26px 0;

                        h6,
                        h4 {
                            font-size: 18px;
                            line-height: 24px;
                            @include fontStyle($font-family-barlow, 16px, 500, 20px, 0px, #20262E);

                        }
                    }
                }
            }

            .banner-image {
                margin-top: 16px;
                position: relative;

                .banner-conatainer {
                    display: inline-flex;
                    align-items: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    padding: 10px 10px;
                    width: 100%;

                    .banner-info {
                        // position : absolute;
                        // top      : 50%;
                        // transform: translateY(-50%);
                        // left     : 16px;
                        // width    : 159px;
                        @include fontStyle($fontFamily-default, 14px, 500, 18px, 0px, #373937);

                        img {
                            margin-right: 5.26px;
                        }

                        span {
                            font-weight: 700;
                        }
                    }
                }
            }

            @media screen and (max-width: 1024px) {
                padding: 10px 26px 5px 27px;

                .order-sum {
                    &__items {
                        row-gap: 8px;
                    }

                    &__item {

                        @media screen and (max-width: 1024px) {
                            margin-bottom: 7px;
                        }

                        h6,
                        h4 {
                            font-size: 14px;
                            line-height: 18px;
                            @include fontStyle($fontFamily-default, 16px, 500, 20px, 0px, #20262E);
                        }

                        &.total {
                            margin-top: 20px;
                            padding: 10px 0;

                            h6,
                            h4 {
                                font-size: 16px;
                                line-height: 21px;
                            }
                        }
                    }
                }

                .banner-image {

                    .banner-conatainer {
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        border: none !important;
                        // background-color: #FFFBEB;
                        background-size: cover;
                        background-repeat: no-repeat;
                        padding: 10px 10px;

                        @media screen and (max-width: 1024px) {
                            margin-top: 0px;
                        }

                        .banner-info {
                            // left : 13px;
                            // width: 125px;
                            @include fontStyle($fontFamily-default, 14px, 500, 38px, 0px, #535664);

                            img {
                                margin-right: 5.26px;
                            }

                            span {

                                @include fontStyle($font-family-barlow, 14px, 600, 38px, 0px, #535664);

                            }

                        }
                    }
                }
            }
        }
    }

    .checkout-process {
        width: 67%;
        padding: 41px 41px 100px;
        max-width: calc(67% - 40px);

        .apply-offer-express-checkout-mweb {
            margin-top: 20px;
        }

        .checkout-flow-all-offers-mweb {
            margin-top: 20px;
            // margin-bottom: 30px;
        }

        .payment-methods {
            font-size: 14px;
            font-weight: 400;
            margin-top: 18px;
        }

        .reward_info_wrap {
            margin-top: 10px;
        }

        @media screen and (min-width: 768px) {
            position: relative;
            height: fit-content;

            .secure-payments {
                clear: both;
                width: 100%;
                position: relative;
                bottom: -156px;
                display: inline-flex;

                span {
                    padding: 0px 6px;
                    font-size: 13px;
                    line-height: 16px;
                    color: #212121;
                }
            }
        }

        .shipping-addresss {
            margin-top: 36px;

            .selct-add-section {
                float: none;
                border-bottom: none;
                padding: 10px 20px;
                border-radius: 10px;
                margin-bottom: 24px;
                background: #FFFFFF;
                filter: drop-shadow(0 3px 6px rgba($color: #0000008B, $alpha: 0.2));

                .usr-add-cntnr {
                    .usr-add {
                        flex-direction: column;

                        .head-content {
                            position: relative;
                            display: flex;
                            align-items: center;

                            .edit_packeg {
                                position: absolute;
                                right: 0;
                                display: flex;
                            }

                            img {
                                position: absolute;
                                right: 0;
                            }

                            .add-type {
                                @include fontStyle($font-family-barlow, 16px, $bold, 21px, 0px, $light-blue_new);
                                margin-right: 13px;
                            }

                            button.address-action-btn {
                                margin: 0;
                                border: none;
                                @include fontStyle($font-family-barlow, 12px, $semibold, 16px, 0px, $header-background);
                                background: #f2f3f5;
                                padding: 3px 10px;
                                border-radius: 4px;
                                width: auto;
                                height: auto;
                                margin-right: 10px;
                            }

                            button.remove-margin-right {
                                margin-right: 0px !important;
                            }

                        }

                        .add-top-container {
                            padding: 10px 0 0;

                            .address-list {
                                .addr-cntnr {
                                    display: flex;
                                    flex-direction: column;

                                    .adress_select {
                                        display: flex;
                                        gap: 10px;
                                        margin: 10px 0px 10px 0px;

                                        .deliver {
                                            display: flex;
                                            padding: 3px;
                                            gap: 6px;
                                            background-color: #000;
                                            color: #fff;
                                            border: 1px solid;
                                            border-radius: 3px;
                                            padding: 5px 12px 5px 12px;
                                        }

                                        .deliver-inactive {
                                            display: flex;
                                            padding: 3px;
                                            gap: 6px;
                                            border: 1px solid;
                                            border-radius: 3px;
                                            padding: 5px 12px 5px 12px;
                                        }
                                    }

                                    .usrdetail {
                                        display: flex;
                                        margin-right: 10px;
                                    }

                                    .addressdetail {
                                        display: flex;

                                        @media screen and (max-width:768px) {
                                            display: inline-block;
                                        }

                                        p {
                                            margin-right: 10px
                                        }
                                    }

                                    span,
                                    p {
                                        @include fontStyle($font-family-barlow, 16px, 400, 21px, 0px, #001D42CC);

                                        @media screen and (max-width: 767px) {
                                            @include fontStyle($font-family-barlow, 14px, 500, 18px, 0px, #87909B);
                                        }
                                    }

                                    @media screen and (max-width: 767px) {
                                        padding-bottom: 14px;

                                        .usrdetail {
                                            line-height: 18px;
                                        }
                                    }

                                    .addressdetail {
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 4;
                                        overflow: hidden;
                                        text-transform: capitalize;

                                        @media screen and (max-width: 1023px) {
                                            -webkit-line-clamp: 3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .add-new-address {
                padding-top: 20px;
                border-top: 1px solid #CCD1D9;

                p {
                    // width: 316px;
                    border: 1px solid rgba($color: #001D42, $alpha: 0.5);
                    border-radius: 2px;
                    display: flex;
                    padding: 11px 22px 12px 22px;
                    display: flex;
                    border-style: dashed;
                    justify-content: center;
                    @include fontStyle($font-family-barlow, 16px, 400, 21px, 0px, #001D42CC);

                    img {
                        margin-right: 12px;
                    }
                }

                @media screen and (max-width: 767px) {
                    padding-top: 0;
                    border-top: none;
                    clear: both;

                    p {
                        width: 100%;
                        font-weight: 500;
                    }
                }
            }

            .delete-address-container {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba($color: #001D42, $alpha: 0.5);
                z-index: 999999;

                .delete-address-wrapper {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background: #FFFFFF;
                    border-radius: 10px;
                    padding: 10px;
                    width: 577px;
                    height: auto;

                    .close-icon {
                        text-align: right;

                        img {
                            cursor: pointer;
                            padding: 10px;
                        }
                    }

                    .body-content {
                        text-align: center;
                        margin-top: -20px;

                        .msg {
                            max-width: 348px;
                            margin: 28px auto 0;

                            span {
                                @include fontStyle($Poppins, 22px, 400, 30px, 0px, #000000);

                                &.red {
                                    color: #D92828;
                                }

                                &.hide {
                                    visibility: hidden;
                                }
                            }
                        }
                    }

                    .footer-btns {
                        margin-top: 24px;
                        padding-bottom: 52px;
                        display: flex;
                        justify-content: center;

                        .btn-cancel {
                            border-radius: 4px;
                            background: #FFFFFF;
                            border: 1px solid #001D42;
                            @include fontStyle($fontFamily-default, 16px, $medium, 21px, 0px, #001D42);
                            padding: 12px 82px;
                            margin-right: 10px;
                            cursor: pointer;
                        }

                        .btn-del {
                            border-radius: 4px;
                            background: #272b65;
                            border: 1px solid #272b65;
                            @include fontStyle($fontFamily-default, 16px, $medium, 21px, 0px, #FFF);
                            padding: 12px 82px;
                            cursor: pointer;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        width: calc(100% - 62px);
                        padding: 0;

                        .body-content {
                            margin-top: 10px;

                            img {
                                width: 100px;
                            }

                            .msg {
                                max-width: 186px;
                                margin: 18px auto 0;

                                span {
                                    @include fontStyle($fontFamily-default, 16px, 500, 21px, 0px, #001D42);
                                }
                            }
                        }

                        .footer-btns {
                            margin-top: 20px;
                            padding-bottom: 42px;
                            flex-direction: column-reverse;
                            align-items: center;

                            .btn-cancel {
                                width: 162px;
                                border-radius: 10px;
                                background: #FFFFFF;
                                border: 1px solid #001D42;
                                @include fontStyle($fontFamily-default, 14px, $medium, 18px, 0px, #001D42);
                                padding: 13px 58px;
                                margin: 10px 0 0;
                            }

                            .btn-del {
                                width: 162px;
                                border-radius: 10px;
                                background: #272b65;
                                border: 1px solid #272b65;
                                @include fontStyle($fontFamily-default, 14px, $medium, 18px, 0px, #FFF);
                                padding: 13px 58px;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            .deliveryBtn {
                width: calc(100% - 52px);
                padding: 11px 0 10px;
                height: 45px;
                @include fontStyle($fontFamily-default, 14px, $medium, 18px, 0px, $black-mb);
            }
        }

        .deliver-btn {
            position: absolute;
            bottom: 30px;

            // right: 41px;
            .deliveryBtn {
                border-radius: 4px;
                background: #18bbdf;
                @include fontStyle($font-family-barlow, 16px, $medium, 21px, 0px, #fff);
                padding: 12px 62px;
                text-transform: uppercase;
                align-items: center;
                justify-content: center;
                display: flex;

                @media screen and (max-width: 1024px) {
                    @include fontStyle($font-family-barlow, 14px, 500, 14px, 0px, #202020);
                    padding: 13px 26px;
                    width: 100%;
                    height: 48px;
                }

            }

            @media screen and (max-width: 1024px) {
                position: fixed;
                margin: 0;
                bottom: 18px;
                text-align: center;
                // background: #FFFFFF;

                width: 164px;
                left: 72.5%;
                transform: translateX(-50%);
                z-index: 10;
            }
        }

        .mb-add-new-sec {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(62, 61, 61, .56);
            z-index: 999999;

            .address-form {
                height: auto;
                margin: 0px 25px;
                min-height: max-content;
                overflow: auto;
                width: 448px;
                max-width: 95%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) !important;
                display: block;
                background-color: #ffffff;
                // box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
                bottom: auto;
                border-radius: 20px;
                overflow-y: auto;

                @media screen and (max-width: 1024px) {
                    max-width: 100%;
                }

                &::-webkit-scrollbar {
                    display: none;
                }

                .address-create {
                    .form-wrap {
                        margin-top: -13px;
                        padding: 0px 25px;
                        max-height: 60vh;
                        overflow: auto;
                        @include scrollbar-thin();

                        .form-group {
                            position: relative;
                            margin: 28px 0 0px !important;
                            width: 100%;

                            @media screen and (max-width: 1023px) {
                                margin: 30px 0 0px !important;

                            }

                            select {
                                box-shadow: none;
                                border: none;
                                border-radius: 2px;
                                // border-bottom: 1px solid rgba($color: #001D42, $alpha: 0.5);
                                @include fontStyle($font-family-barlow, 16px, 400, 21px, 0px, #080A08);
                                background-color: #F7F8F9 !important;
                                padding: 0px 4px;
                            }

                            input {
                                @include fontStyle($fontFamily-default, 16px, 400, 21px, 0px, #001D42CC);
                                padding: 12px 4px;
                                display: block;
                                border: none;
                                border: 1px solid #F7F8F9;
                                box-shadow: none;
                                width: 100%;
                                border-radius: 2px;

                                @media screen and (max-width: 1023px) {
                                    padding: 12px 4px;
                                }


                            }

                            input:-webkit-autofill {
                                background-color: #F7F8F9 !important;
                                -webkit-box-shadow: 0 0 0 1000px #F7F8F9 inset !important;
                                // -webkit-text-fill-color: #FFFFFF !important;
                            }

                            input:focus {
                                outline: none;
                            }

                            .err-txt-yellow {
                                display: inline-block;
                                color: #fb8c00 !important;
                                vertical-align: middle;
                                bottom: 10px;
                                font-size: .9em;
                                line-height: 1.2em;
                                margin-bottom: 5px;
                                margin-top: 5px;

                                img {
                                    display: inline-block;
                                    height: 12px;
                                    vertical-align: top;
                                }
                            }

                            label {
                                @include fontStyle($fontFamily-default, 16px, 400, 21px, 0px, #001D42CC);
                                position: absolute;
                                pointer-events: none;
                                left: 4px;
                                top: 15px;
                                transition: 0.2s ease all;
                                -moz-transition: 0.2s ease all;
                                -webkit-transition: 0.2s ease all;
                                max-width: 300px;
                                @include text-overflow();

                                span {
                                    color: #D92828;
                                }

                                .hide {
                                    visibility: hidden;
                                }

                                &.top {
                                    top: -28px;
                                    left: 4px;

                                    @media screen and (max-width: 1023px) {
                                        top: -28px;
                                        left: 3px;
                                    }

                                    @include fontStyle($fontFamily-default, 10px, 400, 13px, 0px, #001D42CC);
                                }
                            }

                            input:focus~label {
                                top: -28px;
                                @include fontStyle($fontFamily-default, 10px, 400, 13px, 0px, #001D42CC);
                            }

                            input:not(:placeholder-shown) {
                                top: -12px;
                                @include fontStyle($font-family-barlow, 16px, 400, 21px, 0px, $header-background);
                                background: #F7F8F9 !important;

                                input:-webkit-autofill {
                                    background-color: #FFFFFF !important;
                                    -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset !important;
                                    // -webkit-text-fill-color: #FFFFFF !important;
                                }

                                input:-webkit-autofill {
                                    -webkit-box-shadow: 0 0 0 50px white inset;
                                    /* Change the color to your own background color */
                                    -webkit-text-fill-color: #333;
                                }

                                input:-webkit-autofill:focus {
                                    -webkit-box-shadow: 0 0 0 50px white inset;
                                    -webkit-text-fill-color: #333;
                                }
                            }

                            .err-mssg {
                                @include fontStyle($fontFamily-default, 10px, 400, 13px, 0px, #DF2716);
                                padding: 8px 5px 0 2px;
                            }

                            .err {
                                color: #DF2716 !important;
                            }

                        }

                        .width-two {
                            display: inline-flex;
                            width: 100%;

                            .width50 {
                                width: 50%;
                                margin: 0px 4px;

                                .pointer-event-no {
                                    pointer-events: none !important;

                                    .opacity {
                                        opacity: .6;
                                    }
                                }
                            }
                        }

                        .col-span-row {
                            margin: 0;

                            .col-spn-cell {
                                padding: 0px 4px;
                            }

                            .col-sm-6 {
                                &:nth-child(odd) {
                                    padding: 0 6px 0 0;
                                }

                                &:nth-child(even) {
                                    padding: 0 0 0 6px;
                                }
                            }

                            .width50 {
                                width: 50%;

                                .pointer-event-no {
                                    pointer-events: none !important;

                                    .opacity {
                                        opacity: .6;
                                    }
                                }
                            }
                        }

                        .address-type {
                            padding-top: 10px;
                            margin-top: 14px;
                            //border-top: 8px solid #F2F3F5;
                            display: flex;
                            align-items: center;

                            .title {
                                @include fontStyle($fontFamily-default, 16px, 400, 21px, 0px, rgba($color: #001D42, $alpha: 0.7));
                            }

                            .type-list {
                                padding-left: 5px;
                                display: flex;

                                .type {
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    position: relative;
                                    background: #F2F3F5;
                                    border-radius: 4px;
                                    padding: 7px 10px;
                                    margin-left: 10px;

                                    input {
                                        width: 100%;
                                        position: absolute;
                                        opacity: 0;
                                        cursor: pointer;
                                        z-index: 1;
                                        height: 20px;
                                    }

                                    img {
                                        margin-left: 19px;
                                        width: 15px;
                                    }

                                    label {
                                        @include fontStyle($fontFamily-default, 14px, 400, 18px, 0px, rgba($color: #001D42, $alpha: 0.7));
                                    }
                                }
                            }
                        }

                        .default-address-checkbox {
                            padding-top: 10px;
                            // margin-top : 69px;
                            // border-top : 1px solid rgba($color: #1B285C, $alpha: 0.2);
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            position: relative;
                            font-family: $font-family-barlow;

                            input {
                                width: 100%;
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                                z-index: 1;
                                height: 20px;
                            }

                            img {
                                margin-right: 11px;
                            }

                            label {
                                @include fontStyle($fontFamily-default, 16px, 400, 21px, 0px, rgba($color: #001D42, $alpha: 0.7));
                            }
                        }
                    }

                    .form-row {
                        .checkout-cont-new {
                            text-align: center;
                            margin: 33px 0px;

                            @media screen and (max-width: 1023px) {
                                margin: 34px 0px;
                            }

                            .save-btn {
                                border-radius: 2px;
                                border: 1px solid #18bbdf;
                                background: #18bbdf;
                                @include fontStyle($font-family-barlow, 16px, $semibold, 21px, 0px, #FFF);
                                padding: 12px 90px;
                                // margin-left: 10px;
                            }
                        }

                        .checkout-cont {
                            margin-top: 53px;
                            text-align: right;

                            .cancel-btn {
                                border-radius: 2px;
                                background: #FFFFFF;
                                border: 1px solid #001D42;
                                @include fontStyle($font-family-barlow, 16px, $semibold, 21px, 0px, $header-background);
                                padding: 12px 82px;
                            }

                            .save-btn {
                                border-radius: 2px;
                                border: 1px solid #FFD800;
                                background: #FFD800;
                                @include fontStyle($font-family-barlow, 16px, $semibold, 21px, 0px, $header-background);
                                padding: 12px 90px;
                                // margin-left: 10px;
                            }
                        }
                    }

                    @media screen and (max-width: 767px) {
                        .address-create {
                            .form-wrap {
                                margin-top: -50px;

                                .form-group {
                                    margin-top: 0;

                                    label {
                                        &.top {
                                            top: -5px;
                                        }
                                    }

                                    input:focus~label {
                                        top: -5px;
                                    }

                                    input:not(:placeholder-shown) {
                                        top: -5px;
                                    }
                                }

                                .col-span-row {
                                    &.w100 {
                                        display: inline-block;
                                        width: 100%;

                                        .col-sm-6 {
                                            width: 100%;
                                        }
                                    }
                                }

                                .address-type {
                                    padding-top: 22px;
                                    padding-left: 24px;
                                    margin-top: 14px;
                                    flex-direction: column;
                                    align-items: flex-start;

                                    .type-list {
                                        padding: 10px 0 0;

                                        .type {
                                            margin: 0 10px 0 0;
                                        }
                                    }
                                }

                                .default-address-checkbox {
                                    margin-top: 21px;
                                    padding-left: 24px;
                                    margin-bottom: 20px;
                                }
                            }
                        }

                        .checkout-cont {
                            margin-top: 20px !important;
                            position: fixed;
                            bottom: 20px;
                            left: 21px;
                            right: 21px;
                            padding-top: 20px;
                            background: #FFFFFF;
                            z-index: 1;
                            width: calc(100% - 42px);
                            text-align: unset !important;

                            a {
                                display: inline-block;
                                width: calc(50% - 5px);
                                padding: 13px 0 !important;
                                text-align: center;
                                font-size: 14px !important;
                                line-height: 18px !important;
                            }

                            .cancel-btn {
                                margin-right: 5px;
                            }

                            .save-btn {
                                margin-left: 5px !important;
                            }
                        }
                    }
                }

                .top-header-address {
                    display: flex;
                    justify-content: space-between;
                    padding: 16px 21px;

                    .title {
                        @include fontStyle($fontFamily-default, 16px, $bold, 22px, 0px, #080A08);
                    }
                }

                .address-create {
                    padding-top: 5px;
                }

                .contact {
                    padding-left: 30px;
                }

                @media screen and (max-width: 1023px) {
                    height: auto;
                    margin: 0px;
                    min-height: max-content;
                    position: absolute;
                    bottom: 0;
                    background: #fff;
                    -webkit-box-shadow: 0 3px 6px #00000029;
                    -moz-box-shadow: 0 3px 6px #00000029;
                    box-shadow: 0 3px 6px #00000029;
                    border-radius: 16px 16px 0 0;
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    margin: 0;
                    max-height: 95%;
                    overflow-y: auto;
                    width: 100%;
                    top: initial;
                    transform: translate(-50%, 0%) !important;
                }

            }
        }

        .deliveryBtn {
            border-radius: 10px;
            background: #FFD800;
            @include fontStyle($fontFamily-default, 16px, $medium, 21px, 0px, #001D42);
            padding: 12px 62px;
        }
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;

        .order-summary {
            width: 100%;
            background-color: #F2F3F5;
        }

        .checkout-process {
            width: 100%;
            padding: 20px 16px 0px 16px;
            max-width: 100%;

            @media screen and (max-width:1023px) {
                padding: 0px 16px 0px 16px;
            }

            .pdp-accordion {
                background-color: #F2F3F5;
                border-radius: 5px;
                margin-top: 10px;
                padding: 10px 0px 10px 0px;

                .coupn-apply__container {
                    .coupn-apply__form {
                        padding: 5px 10px 10px;
                    }

                    .invalid-coupon {
                        color: #ED0000;
                        display: flex;
                        gap: 50px;
                        cursor: pointer;
                        justify-content: space-between;

                        p {
                            position: relative;

                            img {
                                position: absolute;
                                right: 26px;
                                top: 5px;
                            }
                        }
                    }

                    .coupon-data {
                        display: flex;
                        flex-direction: row;
                        border: 1px dashed;
                        margin: 10px 0px 10px 0px;
                        padding: 5px 10px 5px 10px;

                        .coupons-item {
                            display: flex;
                            flex-direction: column;

                            .coupon-value {
                                font-weight: 600;
                            }

                            .coupon-name {
                                span {
                                    color: #01A685;
                                }
                            }
                        }

                        p {
                            position: absolute;
                            right: 50px;
                            margin-top: 13px;
                            cursor: pointer;
                        }
                    }
                }

                .pdp-accordion__header {
                    border-bottom: none;

                    .pdp-accordion__title {
                        padding-left: 25px;
                    }

                    .amount-summry {
                        position: relative;
                        right: 39px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                    }

                    img {
                        position: absolute;
                        right: 33px;
                    }
                }
            }

            .deliver_to {
                display: flex;
                gap: 20px;
                background-color: #F2F3F5;
                margin-top: 11px;
                padding: 10px 0px 10px 0px;
                border-radius: 5px;

                @media screen and (max-width: 1024px) {
                    margin-bottom: 20px;
                    border-top: 8px solid #F2F3F5;
                    background: #fff;
                    border-bottom: 8px solid #F2F3F5;
                    border-radius: 0px;

                }

                .deliver_action {
                    position: absolute;
                    right: 25px;

                    .change {
                        @include fontStyle($font-family-barlow, 14px, 600, 18px, 0px, #4192DF);
                        // text-decoration: underline;
                        vertical-align: bottom;
                        text-transform: uppercase;
                    }
                }

                .location {
                    // display     : flex;
                    padding-left: 12px;
                    width: 80%;

                    .usrname {
                        @include fontStyle($fontFamily-default, 16px, 600, 21px, 0px, #080A08);

                        @media screen and (max-width: 1024px) {
                            @include fontStyle($fontFamily-default, 14px, 500, 18px, 0px, #000000);
                        }
                    }

                    .address-line {
                        padding-top: 10px;

                        @media screen and (max-width: 1024px) {
                            @include fontStyle($fontFamily-default, 14px, 500, 18px, 0px, #000000);
                        }
                    }

                    .deliver {
                        @include fontStyle($fontFamily-default, 16px, 600, 22px, 0px, #080A08);
                        padding-bottom: 10px;

                    }

                    .last {
                        padding-left: 4px;
                    }
                }
            }

            .shipping-addresss {
                margin: 20px 0 60px;

                .selct-add-section {
                    float: left;
                    filter: drop-shadow(0 0px 2px rgba(0, 0, 0, 0.2));
                }
            }

            .add-new-address {
                padding-top: 0;
                border-top: none;
                clear: both;
                margin: 13px 0px 13px 0px;

                @media screen and (max-width: 1024px) {
                    margin: 20px 0px 16px 0px;

                }

                p {
                    border: 1px solid rgba(0, 29, 66, 0.5);
                    border-radius: 2px;
                    display: flex;
                    padding: 11px 22px 12px 22px;
                    display: flex;
                    border-style: dashed;
                    justify-content: center;
                    font-family: "Roboto";
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0px;
                    color: #001D42CC;

                    @media screen and (max-width: 1024px) {
                        width: 99%;
                    }
                }
            }

            .secure-payments {
                padding-top: 10px;
            }
        }
    }

    .hk-loader-default {
        position: fixed;
        background: rgb(238, 238, 238);
        opacity: 0.5;
        width: 100%;
        height: 100%;
    }
}

.checkout-review-order {
    // padding-bottom: 89px;

    .cart-item__quantity {
        display: flex;
        justify-content: space-between;
        align-items: center;


        button.cart-wishlist.btn-default {
            display: flex;
            border: 0;
            background: transparent;
            gap: 10px;
        }

        .quantitySelector {
            border: 0;
        }
    }

}

.checkout-process {
    .payment {
        .free-checkout-text {
            font-size: 15px;
            padding: 20px 0px 15px 0px;
            font-weight: 500;
        }

        .confirm {
            border-radius: 10px;
            background: #FFD800;
            font-family: "Barlow";
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            position: absolute;
            bottom: 0;
            right: 41px;
            letter-spacing: 0px;
            color: #001D42;
            color: #001D42 !important;
            padding: 12px 62px;

            @media screen and (max-width:1024px) {
                position: fixed;
                bottom: 18px;
                left: auto !important;
                right: 16px;
                background: #FFFFFF;
                width: 100%;
                padding: 18px 26px 24px;
                width: 45%;
                @include fontStyle($font-family-barlow, 14px, 500, 14px, 0px, #000000);
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFBE00;
                border-radius: 4px;
                padding: 14px 0 13px;
                z-index: 99999999;
            }
        }
    }
}

.save-card-checkbox {
    &.saved__card {
        margin-top: 10px;
        position: relative;
        padding-left: 25px;

        label {
            font-family: "Roboto";
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            color: #001D42CC;
        }

        img {
            position: absolute;
            top: 5px;
            left: 0;
        }
    }
}

.rbi_guideline {
    cursor: pointer;
    font-weight: bold;
    color: black;
    font-size: 12px;
}