@import 'static/css/global/global.scss';
.HK-pagecontainer {
    width: 100%;
    max-width: 1200px;
    float: none;
    margin: 0 auto;
    clear: both;
}

.pdpPageBody {
    .fnt-14 {
        @include fontStyle($font-family-barlow, 14px, 500, 18px, 0px, $color-primary);
    }

    .medium {
        font-weight: $medium;
    }

    .regular {
        font-weight: $regular;
    }

    .pdp-wrapper .desktop-pdp-layout .page-header .hk-breadcrumb-cntnr {
        width: 49%;
        padding-right: 10px;

        span {
            @include fontStyle($fontFamily-default, 16px, $medium, 26px, 0px, #262d33);
        }
    }
}

.SPV__ATC {
    .AddToCartnew {

        width: 100%;
        text-align: center;
        white-space: nowrap;
        border: 1px solid #FFBE00;
        border-radius: 4px;
        padding: 11px 0;
        cursor: pointer;
        font-family: $font-family-barlow;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        color: $black-mb;
        background-color: #FFBE00;
        text-transform: uppercase;
    }

    .added {
        background-color: #080A08;
        color: #FFFFFF;
    }
}

.YMAL__container {
    .hk-swiper {
        @media screen and (min-width: 1024px) {
            .YMAL-slider {
                overflow-x: hidden;
            }
        }

        .SPV__outer {
            .SPV__container {
                @media screen and (max-width:1024px) {
                    padding: 9px 10px 13px;
                }

                .discount {
                    right: auto;
                    margin-left: 2%;
                }

            }

            padding: 0 5px;

            .SPV__name {
                font-family: $fontFamily-oswald;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0px;
                color: $black-mb;
                margin: 0;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;

                span {
                    font-family: $fontFamily-oswald;
                    font-size: 500;
                    font-weight: 14px;
                    line-height: 16px;
                    letter-spacing: 0px;
                    color: #0085CB;

                }
            }

            .SPV__check {
                cursor: pointer
            }
        }
    }
}

.PIC {
    &__container {
        .hk-swiper {
            padding: 20px 0;
        }
    }

    &__icons {
        span.wishlist-icon {
            display: flex;
        }

        .pdp-share-icon {
            cursor: pointer;
        }
    }
}

.page-header {
    .delivery-info {
        width: 51%;

        .delvery-sec__row-right {
            position: relative;

            .not_deliver {
                color: #CD594B;
                font-family: $font-family-barlow;
            }

            .deliver_by {
                font-family: $font-family-barlow;
                color: $header-background;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -13px;
                background-color: $color-primary;
                width: 1px;
                height: 50%;
            }
        }
    }
}

.variant-with-price {
    .action-btn {
        .hk-elements--addToCart {
            margin-top: auto;

            button {
                font-size: 14px;
                line-height: 29px;
                border: 1px solid $grey-10;
                background-color: $white;
                font-weight: $semibold;
                color: $black;
                border-radius: 50px;
                cursor: pointer;
                padding: 7px 16px;
            }
        }

    }

    @media screen and (max-width: 768px) {
        .action-btn {
            .hk-elements--addToCart {
                button {
                    border-radius: 0;
                    font-size: 13px;
                    line-height: 20px;
                    padding: 7px 20px 7px 13px;
                }
            }
        }
    }
}

.NRS__container {
    .write-review {
        width: 240px;
        height: 45px;
        border-radius: 10px;
        border: 0;
        background-color: #e57321;
        @include fontStyle($fontFamily-default, 16px, 500, 21px, 0px, #fff);

        img {
            display: none;
        }
    }
}

.faqs-section {
    .acccordion-item {
        padding: 20px 4px 28px 4px;

        &:not(:last-child) {
            border-bottom: 1px solid #ccd1d9;
        }
    }

    .accordion-container {
        padding: 0 32px 0 42px;
        background-color: #e5e8ec80;
        border-radius: 0 0 10px 10px;

        .accordion-label {
            cursor: pointer;
            position: relative;

            span {
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                color: #0f3058;
            }
        }

        .accordion-children {
            font-size: 18px;
            line-height: 24px;
            color: #0f3058;
            padding-top: 20px;
        }

        .toggle-icon {
            position: absolute;
            right: 0;
            top: 4px;
            display: flex;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .accordion-container {
            padding: 0 15px 0 12px;

            .toggle-icon {
                width: 12px;
                height: 12px;
            }

            .acccordion-item {
                padding: 10px 0 18px 0;
            }

            .accordion-label {
                padding-right: 20px;

                span {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 700;
                }
            }

            .accordion-children {
                font-size: 14px;
                line-height: 20px;
                color: #4a5e78;
                padding-top: 10px;
            }
        }
    }
}

.mobile-pdp-layout {
    .pdp-accordion#customer-reviews {
        padding-top: 15px;
        // border-top: 1px solid rgba(#1B285C, 0.1);

        .pdp-accordion__header {
            border: 0;
        }

        .review__section {
            padding-left: 0;
            padding-right: 0;

            .HK-pagecontainer {
                border: 0;
                padding-top: 0;

                .review__head__overall {
                    margin-top: 0;
                }

                h2.head {
                    display: none;
                }
            }
        }

    }
}

.review__head {
    .write-review {
        margin-left: 0;
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        line-height: 2rem;
        color: #fff;
        padding: 3px 17px;
        border: 0;
        border-radius: 12px;
        min-height: auto;
        margin-right: 0;
        outline: 0!important;
        box-shadow: none!important;
        transition: .25s;
        background: #e57321;
        display: block;
        margin-top: 5px;

        img {
            margin-right: 6px;
        }
    }

    .progress-bar__container {
        label.title {
            padding-left: 67px;
        }
    }

    @media screen and (max-width: 767px) {
        .write-review {
            width: 156px;
        }

        .progress-bar__container {
            label.title {
                padding-left: 0;
            }
        }
    }

    .review-modal-fix-container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($color: #001D42, $alpha: 0.79);
        z-index: 999999;

        .write-review-modal {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            border: none;
            border-radius: 10px;
            height: fit-content;
            max-width: 500px;
            max-height: calc(100vh - 20px);
            overflow: auto;

            .write-review-modal-wrapper {
                position: relative;

                .cross-icon {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;

                    @media screen and (max-width: 767px) {
                        top: 13px;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                max-width: calc(100vw - 32px);
                // bottom: 56px;
                // top: unset;
                // transform: translateX(-50%);
                width: 100%;
            }
        }
    }
}

.mobile-pdp-layout {
    .carousel-container {
        .zoom-box-cont {
            .zoom-container {
                .pinch-zoom-container {
                    padding-top: 50px;
                    height: 650px !important;
                }
            }
        }
    }
}

.tbs-share-popup {
    @include widthFloat();

    .pu-box {
        width: 80%;
        max-width: 300px;
        background: #ffffff;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        z-index: 999999;

        // transition: all 1s ease;
        // animation: anim-scale-in .28s cubic-bezier(.4,0,.26,3.0);
        .pu-body {
            @include widthFloat();
            text-align: center;
            padding: 25px 20px;

            .pu-close {
                position: absolute;
                top: 15px;
                right: 15px;
                width: 12px;
                height: 12px;
                cursor: pointer;

                img {
                    width: 100%;
                    vertical-align: middle;
                }
            }

            .pu-body-heading {
                @include widthFloat();
                font-size: 16px;

            }

            .pu-body-content {
                display: flex;
                line-height: 23px;
                margin: 30px 0 0 0;
                justify-content: center;

                a {
                    display: inline-block;
                    width: 45px;
                    height: 45px;
                    margin: 0 25px 0 0;

                    &:last-child {
                        margin: 0;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.prod-content {
    .action-button-container {
        .cta-btns {
            .hk-elements--addToCart {
                width: 100%;
                margin-bottom: 20px;

                button {
                    width: 100%;
                    border: none;
                    outline: none;
                    background: #FFBE00;
                    border-radius: 4px;
                    height: 40px;
                    @include fontStyle($fontFamily-bold, 14px, $medium, 20px, 0px, #202020);
                    text-transform: uppercase;
                }
            }

            .hk-elements--buyNow {
                width: 100%;

                button {
                    width: 100%;
                    border: none;
                    outline: none;
                    background: #FFFFFF;
                    border-radius: 4px;
                    height: 40px;
                    border: .8px solid #535664;
                    @include fontStyle($fontFamily-bold, 14px, $medium, 14px, 0px, #202020);
                }
            }

            &.fixed-cta {
                .hk-elements--addToCart {
                    width: 50%;
                    margin: 0;
                    text-align: left;
                    padding-left: 5px;

                    button {
                        max-width: 156px;
                    }
                }

                .hk-elements--buyNow {
                    width: 50%;
                    margin: 0;
                    text-align: right;
                    padding-right: 5px;

                    button {
                        max-width: 156px;
                    }
                }
            }
        }

        .var-oos {
            font-size: 22px;
            color: $red;
        }
    }
}

.review__section {
    .review-modal-fix-container {
        .user-review-modal {
            .user-review-modal-wrapper {
                .review-item-container {
                    .image-view-container {
                        .hk-swiper {

                            // padding-bottom: 5px;
                            .review-images-carousel {
                                display: flex;
                                flex-direction: column;

                                .swiper-wrapper {
                                    order: 1;
                                    padding: 0 0 6px;

                                    .image-item {
                                        border-radius: 8px;
                                        max-height: 289px;
                                        width: auto !important;
                                        margin: 0 auto;

                                        @media screen and (max-width: 767px) {
                                            max-height: 208px;
                                        }
                                    }
                                }

                                .swiper-pagination {
                                    order: 2;
                                    bottom: -3px;

                                    // opacity: 1;
                                    .swiper-pagination-bullet {
                                        margin: 0 7px;
                                        width: 7px;
                                        height: 7px;
                                        background: rgba($color: #001D42, $alpha: 0.7);
                                        opacity: 0.4;

                                        &.swiper-pagination-bullet-active {
                                            opacity: 1;
                                            background: rgba($color: #001D42, $alpha: 0.75);
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}

.variantcombobox-cont {
    .hk-elements--addToCart {
        padding-left: 5px;

        @media screen and (max-width: 767px) {
            padding-top: 14px;
            padding-left: 0px;
        }

        .track-add-to-cart {
            background-color: #282960;
            border-radius: 2px;
            padding: 4px 28px;
            border: 0px solid #282960;
            margin-left: 30px;
            @include fontStyle($font-family-barlow , 14px, 500, 14px, 0px, #FFF);


            @media screen and (max-width: 767px) {
                margin-left: 0px;
                width: 100%;
            }

        }
    }
}

//global index
/**** MOBILE ****/
.cart_count {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9px;
    left: 8px;
    width: 17px;
    height: 17px;
    background-color: #fde100;
    border: 1px solid #001d42;
    border-radius: 50%;
    text-align: center;
    font-family: "Barlow";
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0;
    color: #000;
}

.header-mweb__outer-container {
    position: relative;

    .header-mweb__container {
        background-color: #FFF !important;
        min-height: 65px;
        // padding: 10px 15px;

        .HK-pagecontainer {
            width: 100%;
            height: inherit;
            // min-height: 76px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            // padding: 21px 20px;
            padding: 10px 15px;
        }

        .header-mweb__logo {
            a svg {
                text-align: center;
                vertical-align: middle;
            }
        }

        .header-mweb__right {
            display: flex;
            align-items: center;
            grid-column-gap: 30px;
            column-gap: 15px;
            position: relative;
            div {
                display: flex;
                align-items: center;
                column-gap: 10px;
            }
            .display_block{
                display: block;
            }
            .account-menu-drawer {
                display: -moz-box;
                display: flex;
                -moz-box-align: center;
                align-items: center;
                grid-column-gap: 10px;
                -webkit-column-gap: 10px;
                -moz-column-gap: 10px;
                column-gap: 10px;
                position: absolute;
                display: block !important;
                position: absolute;
                width: 12rem;
                height: auto;
                padding-bottom: 1.5rem;
                right: 0;
                bottom: -19px;
                transform: translateY(100%);
                border: 1px solid rgba(var(--color-foreground), 0.2);
                background-color: #fff;
                box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2) !important;
                z-index: 9;
                a{
                    display: block;
                    padding: 10px 35px;
                    text-transform: CAPITALIZE;
                    color:#BFC1C3;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .header-top__icons__cart {
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 5px;

                .user-icon-cntnr{
                    position: relative;
                    
                }
            }

            a {
                padding-top: 0;
                position: relative;
                display: flex;
                align-items: center;

                div {
                    padding-top: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
.mobOnly{
    transition: transform 0.5s ease-in-out;
    background: #fff;
    margin-bottom: 0px;
    position: relative;
    max-width: 100%;
    border-bottom: 0.1rem solid rgb(148 152 155 / 20%);
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    .first{
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .categoryBox {
        display: flex;
        padding: 12px 2px;
        margin-bottom: -2px;
        margin-top: 0;
        justify-content: space-between;
        .first{
         li {
            color: #5652ff;
            font-family: Barlow;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            list-style: none;
            a {
                color: #002140;
                font-weight: 600;
                font-size: 12px;

            } 
            .hover-add{
                color: #002140;
                font-weight: 600;
                font-size: 12px;
            }&:hover{
                border-bottom: 1px solid #01070e;;
            }      
            
        }
    }
        .last-li{
            width: 10%;
            position: relative;
            right: 17%;
            .mobileBox {
                display: flex;
                width: 100px;
                padding: 4px 0px;
                border-radius: 10px 4px 4px 10px;
                background-color: #E57322;
                font-family: "Barlow", sans-serif;
                font-size: 10px;
                font-weight: 500;
                line-height: 1.3;
                height: 38px;
                letter-spacing: 0px;
                color: #FFF;
                margin: 10px;
                text-align: center;
            }   
        }

    }
}
.stickyHeader{
    display: none !important;
}

    .search-box__parent {
        opacity: 0;
        visibility: hidden;
    }
}

.cart_count {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9px;
    left: 8px;
    width: 17px;
    height: 17px;
    background-color: #fde100;
    border: 1px solid #001d42;
    border-radius: 50%;
    text-align: center;
    font-family: "Barlow";
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0;
    color: #000;

    @media screen and (max-width: 1024px) {
        height: 15px;
        width: 16px;
        background-color: #3dc47e;
                border-radius: 8px;
        padding: 2px 3px;
        font-family: "Roboto";
        font-size: 9px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        color: #fff;
        border: 0px solid #001d42;
        top: 15px;
        left: 15px;

    }
}

.hk-swiper {
    .pdp-mob-banner-carousel {
        display: flex;
        flex-direction: column;

        .swiper-wrapper {
            order: 1;
            padding: 5px 0 30px;
        }

        .swiper-pagination {
            order: 2;
            bottom: 4px;
            opacity: 1;

            .swiper-pagination-bullet {
                margin: 0 3px;
                height: 6.5px;
                min-height: 6.5px;
                min-width: 6.5px;
                max-width: 6.5px;
                border-radius: 50%;
                background: #080A08;
                opacity: 0.2;

                &.swiper-pagination-bullet-active {
                    height: 8px;
                    min-width: 8px;
                    max-width: 8px;
                    background: #080A08;
                    opacity: 1;
                }

                &.video {
                    clip-path: polygon(100% 50%, 0 0, 0 100%);
                    height: 8px;
                    min-height: 8px;
                    min-width: 8px;
                    max-width: 8px;
                    border-radius: 0;
                }
            }
        }
    }
}

/**** DESKTOP ****/
.header-top__container {
    box-shadow: 0px 3px 10px #717A864D;
    border: 1px solid #00000000;
}

.header-top__container>.container-wrap {
    width: 100%;
    max-width: 1200px;
    float: none;
    margin: 0 auto;
    clear: both;
    color: #fff;
    height: 84px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 0;
    -webkit-transition: all .5s;
    transition: all .5s;
    position: relative;

}

.swiper-slide img {
    display: block;
    width: 100%;
    position: relative;
}

.main-container-wrapper .desktop-header {
    box-shadow: 0 3px 6px #00000029;
}

@media screen and (max-width:1023px) {
    .VWP2 {
        &.prodList {
            .hk-variants-cta {
                .hk-elements--buyNow {
                    width: 50%;
                    margin: 0;
                    text-align: right;
                    padding-right: 5px;

                    button {
                        width: 100%;
                        border: none;
                        outline: none;
                        background: transparent;
                        border-radius: 2px;
                        height: 40px;
                        font-family: "Roboto";
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        letter-spacing: 0px;
                        color: $black-mb;
                    }
                }

                .hk-elements--addToCart {
                    button {
                        width: 100%;
                        outline: none;
                        background: #080A08;
                        border-radius: 2px;
                        height: 40px;
                        font-family: "Roboto";
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        min-width: 156px;
                        border-radius: 2px;
                        border: 1px solid #161616;
                    }
                }
            }
        }
    }
}

.mobile-view {
    .order-invoice-layout {
        padding-top: 56px;

    }

    &.show-head {
        .hk-header-wrapper {
            display: block;
        }
    }

    .hk-header-wrapper {
        display: none;
    }

    .partner-page-wrap {
        .content-secion {
            .partner-list {
                ul {
                    width: 100%;
                }

                li {
                    flex-direction: column;
                    padding: 20px 10px;

                    &:last-child {
                        border-bottom: 1px solid #ccc;
                    }

                    &.no-border {
                        border: 0
                    }

                    .img-wrap {
                        min-width: 170px;
                        width: 150px;
                        padding-right: 20px;

                        img {
                            max-width: 100%
                        }
                    }

                    .content {
                        a {
                            color: #337ab7
                        }
                    }
                }
            }
        }
    }

    .static-accordion-container {
        .accordioin-text {
            .partner-faqs {
                display: flex;

                li {
                    width: 50%;
                    margin-bottom: 30px;

                    .partner-item {
                        margin-bottom: 10px;
                        border-bottom: 1px #ccc solid;
                        padding-bottom: 10px;
                    }

                    .partner-description .partner-item span:first-child {
                        font-weight: bold;
                        margin-right: 10px;
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}

.aboutus-pg {
    .top-banner {
        .aboutus-bredcrumb {
            .home {
                color: #FFFFFF;
            }

            span {
                color: #929495;
            }

            @media screen and (max-width: 1023px) {

                .hk-breadcrumb-cntnr,
                .partner-banner {
                    display: none;
                }
            }
        }
    }
}

.faq-container .clear-both .rght-cont section .ans-cntr .ans.show {
    max-height: 3000px !important;
    transition: max-height 2s ease-in !important;

    .sp-tnc-content-link {
        font-size: 12.5px;
        text-decoration: underline;
        color: $orange;
        cursor: pointer;

        &:hover {
            color: $orange !important;
        }
    }
}

// FAQ Section Show and Hide handling
.faq-container {
    .clear-both {
        .rght-cont {
            section {
                .hide {
                    display: none;
                }

                .ans-cntr {
                    .hide {
                        display: none;
                    }
                }
            }
        }

    }
}

.pro-check {
    .pro-kit {
        .hk-elements--buyNow {
            .track-add-to-cart {
                background-color: #FDE100;
                border: 1px solid #FDE100;
                color: #0A0A0A;
                font-size: 17px;
                line-height: 18px;
                padding: 15px 45px;
                max-width: 160px;
                text-align: center;
                align-items: center;
                font-weight: 600;
                margin: 32px 0px;

                img {
                    display: none;
                }
            }
        }

        .quantitySelector {
            display: flex;
            justify-content: flex-start;

            .fnt-14 {
                color: #FFFFFF;
                font-size: 14px;
                line-height: 20px;
            }

            .hk-elements--quantitySelector {
                background: #Ffffff;
                margin-left: 10px;

                #quantity {
                    background: #9C9D9F;
                }

                #decrement {
                    height: 36px;
                    line-height: 32px;
                    width: 39px;
                }

                #increment {
                    height: 36px;
                    line-height: 32px;
                    position: relative;
                    right: -1%;
                    width: 39px;
                }
            }
        }
    }
}

.birthdayPageWrap .page-sections .section-content {
    .icon-svg {
        svg {
            &.fassil {
                * {
                    stroke: #F8CE00;
                    fill: #000;
                }
            }
        }
    }
}

.tbs-pagecontainer-1170 {
    width: 100%;
    max-width: 1170px;
    float: none;
    margin: 0 auto;
    clear: both;
}

//Used HomeNew/homenew.scss need to optimize it 
.hk-home-container {
    .ytp-button {
        &.ytp-expand {
            display: none
        }
    }

    .title-wrap {
        position: relative;
        padding-bottom: 12px;
        margin-bottom: 12px;

        @media (max-width: 768px) {
            margin-bottom: 0px;

            .dummble-image {
                position: absolute;
                left: -7px;
                top: 6px;
            }

            img {
                width: 43px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 120px;
            height: 3px;
            // background: linear-gradient(to right, #001D42 65%, transparent 65%, transparent 75%, grey 75%, grey 100%);
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: 768px) {
                width: 80px;
                height: 2px;
                left: 50%;
                // background: linear-gradient(to right, #001D42 65%, transparent 65%, transparent 75%, #1675B0 75%, #1675B0 100%);
            }
        }

        &.blue {
            // &::after {
            //     background: linear-gradient(to right, #001D42 65%, transparent 65%, transparent 75%, grey 75%, grey 100%);
            // }
        }
    }

    .title-line {
        @media screen and (max-width: 1024px) {
            height: 20px;
        }

        height: 44px;
        width: 4px;
        background-color: #FFBE00;
        margin-right: 10px;
    }

    .title {
        @include fontStyle($font-family-barlow, 40px, 500, 60px, 0, $blac-text-color);

        @media(max-width:767px) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    .txt-yellow {
        color: $white-color !important;
    }

    .txt-blue {
        color: $blac-text-color !important;
    }

    .description {
        max-width: 756px;
        margin: 0 auto;
        @include fontStyle($font-family-barlow, 22px, 400, 32px, 0, #FFFFFF);
        text-align: center;

        @media (max-width: 768px) {
            font-size: 13px;
            line-height: 20px;
            margin-top: 8px;
        }

        &.dark {
            color: $color-primary;
            top: 7px;
        }
    }
}

//Fix it ,Search Mobile header for className "search-box__parent"
.header-mweb__outer-container .search-box__parent {
    position: absolute;
    top: 0;
}

//imported from 'components/Cart/cart.scss' need to optimize this 
.apply-offer__items {
    .hk-swiper {
        width: 100%;

        &.SpOdesk {
            padding: 0 25px;
            position: relative;

            .desk {
                position: unset;
            }
        }

        .SpO {
            .swiper-wrapper {
                display: flex !important;
                position: unset;

                .swiper-slide {
                    display: flex;
                    height: auto;

                    div {
                        display: flex;
                    }
                }

                @media screen and (max-width:768px) {
                    width: max-content !important;
                    padding: 0px 0px;
                    margin-top: 10px;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                height: 23.5px;
                width: 23.5px;
                top: 54%;
                background-size: contain;
                z-index: 5;

                &::after {
                    display: none;
                }
            }

            .swiper-button-prev {
                background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/grey_arrow_left.svg) !important;
                left: 0 !important;
            }

            .swiper-button-next {
                background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/grey_arrow_right.svg) !important;
                right: 0 !important;
            }

            .swiper-button-disabled {
                opacity: 0.1;
                pointer-events: auto !important;

                &:hover {
                    cursor: not-allowed !important;
                }
            }
        }
    }
}

//Checkout button imported from ordersummary.scss directed to proceedtocheckout.js 
.width-100 {
    width: 100% !important;

    .order-sum__checkout.btn.checkout-btn {
        margin-top: 10px;
        width: 100%;
        height: 60px;
        background-color: #fde100;
        font-family: 'Roboto';
        font-size: 22px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0;
        color: #080a08;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        @media screen and (min-width: 1024px) {
            text-transform: uppercase;
            background-color: #282960;
            padding: 14px 0px;
            @include fontStyle($font-family-barlow, 15px, 600, 14px, 0px, #20262E);
            height: auto;
            border-radius: 5px;
            color: #fff;
            border: 1px solid #282960;
            border-radius: 20px;
            transition: .8s;
            &:hover{
                background-color: #fff;
                color: #282960;
            }
        }
    }
}

//Add to Cart and Quick buy Buttons for PLP page
.hk-variants-cta-top-category {
    @media screen and (max-width: 1024px) {
        border-top: none !important;
        position: absolute;
        right: 0px;
        top: 50px;

        .hk-elements--addToCart {
            button {
                min-width: 26px !important;
                height: 26px !important;
                @include fontStyle($font-family-barlow !important, 32px !important, 500, 32px, 0, #000);
                padding-top: 2px;

            }
        }
    }
}

.hk-variants-cta-top-category,
.hk-variants-cta {
    padding: 9px 9px 0px 9px;
    border-top: 1px solid #ebecf1;
    display: flex;

    .hk-elements--addToCart {
        @media screen and (max-width: 1024px) {
        width: 50%;
        }
        .sale-page-button {
            background: #080a08 !important;
            color: #fff;
            min-width: 200px;
            text-transform: none;

        }
    }

    .hk-elements--buyNow {
        width: 50%;
        margin: 0;
        text-align: right;
        padding-right: 5px;

        .sale-page-button {
            border: none;
            outline: none;
            border-radius: 2px;
            height: 40px;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0;
            text-transform: none;
        }

        button {
            width: 100%;
            border: 0.8px solid #53566480;
            outline: none;
            background: transparent;
            border-radius: 2px;
            height: 40px;
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0px;
            color: #080A08;
            text-transform: uppercase;
        }
    }

    .hk-elements--addToCart {
        .add-to-cart-button-forword-icon {
            height: 13px;
            width: 8px;
            margin-left: 17px;

            @media screen and (max-width: 1024px) {
                margin-left: 8.60px;
                height: 10.8px;
                width: 6.3px;
            }
        }
        button {
            width: 100%;
            border: none;
            outline: none;
            background: #FFBE00;
            border-radius: 2px;
            height: 40px;
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0px;
            color: #20262E;
            min-width: 200px;
            border-radius: 2px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 1024px) {
                min-width: fit-content;
                padding-bottom: 5px;
            }
        }
    }
}

//imported from _desktop/index.module.scss  ,Overrride properties for collapse-wrap
.category-main-container {
    .cat__main-container {
        .sticky--filter {
            .filter-box {
                .fltr-box-cont {
                    .collapsible-box {
                        .collapsed {
                            .sc-cat-nm img {
                                top: 10px;
                                transform: rotate(-90deg);

                            }

                            .collapse-wrap {
                                max-height: 230px;
                                margin-left: 0;
                                margin-top: 5px;

                                ul.gl {
                                    max-height: 230px;
                                    margin: 0 0 15px;
                                }
                            }
                        }
                    }

                    // }
                }
            }
        }
    }
}

//Need to FIX{imported from minmaxslider.scss}
.price-range-selector2 {
    .slider {
        width: 100%;
        float: left;

        .bar {
            background: #E5E8EC;
            height: 6px;
            top: 0px;
            border-radius: 10px;

            // margin-right: 21px;
            &.bar-1 {
                background: $color-primary;
                border-radius: 6px;
                z-index: 1;
            }
        }

        .handle {
            cursor: pointer;
            border-radius: 0;
            background: none;
            border: none;
            box-shadow: none;
            // margin-top: 10px;
            display: inline-block;
            width: 6px;
            height: 6px;
            // background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/plp/pin-drop.svg);
            // background-repeat: no-repeat;

            .dot-container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 12px;
                //  right: 0px;

                img.dot {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 12px;
                    height: 12px;
                }

                img.pin {
                    position: absolute;
                    left: 50%;
                    top: 12px;
                    transform: translateX(-50%);
                }
            }

            span {
                // @include priceRangeTextStyle;
                position: absolute;
                top: -31px;
                right: -14px;
                background-color: #ffffff;
            }

            &.handle-0 {
                span {
                    left: 0;
                }

                &::after {
                    right: -6px;
                }

                &::before {
                    right: -3px;
                }
            }

            &.handle-1 {
                span {
                    right: 17px;
                }

                &::before {
                    left: -3px;
                }

                &::after {
                    right: -6px;
                }
            }

            &::after {
                content: " ";
                position: absolute;
                top: 12px;
                width: 18px;
                height: 26px;
                background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/plp/price_filt.svg);
                background-repeat: no-repeat;
            }

            &::before {
                content: " ";
                position: absolute;
                top: -2px;
                width: 12px;
                height: 12px;
                background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/plp/dot_circle.svg);
                background-repeat: no-repeat;
            }
        }
    }
}

//confirm it (imported from paymentmodes.module.scss)
.payment-modes .payment-modes__list .payment-modes__list-item.selected {
    background-color: #080a08;

    h2 {
        color: #fff;
        opacity: 1;
    }
}

//Need to Fix (imported from myaccount.module.scss)
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:focus {
    outline: none;
}

textarea {
    height: 50px;
}

//prod-price from product recommentdation global
.prod-price {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .hk-variants-price {
        margin: 0;

        .flex {
            display: none;
        }

        .variant-price {
            margin-right: 10px;
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
            color: #2C2C2C;
        }

        .variant-old-price {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #898989;
            text-decoration: line-through;
            opacity: 1;
            margin-right: 0;
        }
    }

    .prod-discount {
        margin-left: 11px;
        color: #22B673;
        font-size: 10px;
        line-height: 16px;
    }
}

//Product Recommendation Section ATC and Buy Now
.varient-footer-mob {
    .hk-variants-box-footer {
        .hk-variants-cta {

            .hk-elements--addToCart,
            .hk-elements--buyNow {
                width: 100px !important;
            }
        }
    }
}

//Need to FIX , Imported for variant-img width 'components\ProductRecommendation\Mweb\Variant.js'
.hk-variants-box-body--left {
    .variant-img-wrap {

        .variant-img {
            height: 75px;

            img {
                width: auto;
                max-height: 100%;
                max-width: 100%;
            }
        }
    }

    .recommendation-main {
        .variant-img {
            height: 144px;
        }
    }
}

//Need to Fix ,Product recommendation footer buttons
.varient-footer-mob {
    .hk-variants-box-footer {
        .hk-variants-offer-ribbon {
            display: none;
        }

        .hk-variants-cta {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            width: 100%;
            border: none !important;

            .hk-elements--addToCart {
                width: 49%;

                button {
                    background-color: #423838;
                    color: #fff;
                    font-size: 12px;
                    line-height: 22px;
                    border-radius: 4px;
                    font-weight: 500;
                    border: none;
                    cursor: pointer;
                    width: 100% !important;
                    height: 34px;
                    min-width: 49%;
                }
            }

            .hk-elements--buyNow {
                width: 49%;

                button {
                    color: #2C2C2C !important;
                    background-color: #FAC833 !important;
                    font-size: 12px !important;
                    line-height: 22px;
                    border-radius: 4px;
                    font-weight: 700 !important;
                    border: none;
                    cursor: pointer;
                    margin-left: 8px !important;
                    width: 100% !important;
                    height: 34px;
                }
            }
        }
    }
}

.cart-drop {
    align-items: center;

    .hk-elements--addToCart {
        padding-right: 10px;
        width: 50%;
        white-space: nowrap;
        text-align: center;

        .track-add-to-cart {
            display: flex;
            align-items: center;
            background-color: #fff;
            border: 1px solid #fff;
            color: #080a08;
            font-size: 14px;
            
            span {
                .wish-img {
                    margin-top: 10px;
                    padding-right: 13px;
                }
            }
        }
    }
}

//Need to FIX ,Checkout button cart mobile imported from cart.module.scss
.cart-page {
    display: flex;
    flex-direction: column;

    @media(max-width:767px) {
        padding: 0 16px;
        padding-bottom: 30px;
    }

    &__content {
        display: flex;
        column-gap: 30px;
        width: 100%;

        @media screen and (max-width:768px) {
            flex-direction: column;
            row-gap: 40px;

            .width-100 {
                width: 100% !important;
                background: #fff;
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 2;
                height: 90px;

                .order-sum__checkout.btn.checkout-btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 45px;
                    font-size: 16px;
                    margin: 0;
                    border-radius: 2px;
                    width: 90%;
                }
            }
        }
    }
}

//Need to Fix, Reviews-Page
.reviews-page {
    .HK-pagecontainer {
        .pdp-sticky-header .hk-breadcrumb-cntnr {
            padding: 15px 0 20px;
            border-bottom: 1px solid rgba(#1B285C, .2);
            // @include text-overflow;

            &>span:last-child span {
                color: rgba($color-primary, .5);
                font-weight: 500;
            }
        }
    }
}

.fav-inf__container {
    .fav-inf__sliders {
        .hk-swiper {
            .swiper-container {

                .swiper-button-prev,
                .swiper-button-next {
                    position: absolute;
                    bottom: 25px;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    background: #bdbdbd;
                    left: auto;
                    top: auto;

                    &::after {
                        color: #000000;
                        content: "";
                        border: solid #212221;
                        border-width: 0 3px 3px 0;
                        display: inline-block;
                        padding: 5px;
                        transform: rotate(135deg);
                    }
                }

                .swiper-button-prev {
                    right: 120px;
                }

                .swiper-button-next {
                    right: 65px;

                    &::after {
                        transform: rotate(315deg);
                    }
                }
            }
        }
    }
}

.cart-pack-item .cartPackChild .cartPlus-combo .cart-item {
    padding: 0 12px 16px;

    @media screen and (min-width: 1024px) {
        padding: 0 0px 0px;

    }
}

.cart-pack-item {
    .cartPackChild {
        .cartPlus-combo {
            .cart-item__details {
                .cart-item__image {
                    width: 140px;
                    height: 140px;
                    min-width: 140px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.cart-pack-item {
    .cartPackChild {
        .cartPlus-combo {
            .plus_icon {
                height: 0px;
                bottom: 13px;
                margin: 0;
                position: absolute;
                left: 50px;
                z-index: 1 !important;

                @media screen and (max-width:768px) {
                    bottom: 0px;
                    left: 51px;
                }

                img {
                    height: 44px;
                }
            }
        }
    }
}

.cart-pack-item {
    .cartPackChild {
        .cartPlus-combo {
            .plus_icon {
                height: 0px;
                bottom: 13px;
                margin: 0;
                position: absolute;
                left: 50px;
                z-index: 1 !important;

                @media screen and (max-width:1024px) {
                    bottom: 4px;
                    left: 51px;
                }

                @media screen and (min-width: 1024px) {
                    bottom: 0px;
                }

                img {
                    height: 44px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .cart-page__content .column-1 .cart-pack-item .cart-item {
        // background: #fff 0 0 no-repeat padding-box;
        box-shadow: 0 0 0 #fff;
        opacity: 1;
        margin: 0;
        border: none;
        border-bottom: 1px solid #d1d4de;

        border-radius: 0px;
    }

    .cart-pack-item .cartPackChild .cartPlus-combo .cart-item {
        padding: 19px 12px;
        position: relative;
        top: -4px;

        @media screen and (max-width: 1024px) {
            padding: 13px 0px 16px 0px;
            // margin: 19px 12px 10px !important;

        }
    }

    .cart-page__content .column-1 .cart-item {
        // background: #ffe2dd 0 0 no-repeat padding-box;
        box-shadow: 0 -3px 10px #0000001A;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1024px) {
            // z-index: 2;
        }
    }
}

.cart-pack-item {
    .cartPackChild {
        .cartPlus-combo {
            .cart-item__details {
                padding-bottom: 16px;
                border-bottom: 1px solid #1b285c33;

                @media screen and (min-width: 1024px) {
                    padding: 16px 0px;
                }

                @media screen and (max-width:768px) {
                    padding: 0;
                    border: none;
                }

                .cart-item__image {
                    width: 140px;
                    height: 140px;
                    min-width: 140px;

                    @media screen and (max-width : 1024px) {
                        padding: 10px 15px;
                        width: 115px;

                    }

                    img {
                        width: 100%;


                    }
                }

                .cart-item__desc {
                    .cart-item__name {
                        font-size: 18px;

                        @media screen and (max-width:1024px) {
                            font-size: 14px;
                            max-width: 90%;
                        }
                    }

                    .cart-item__price {
                        .cart_item__offer-price {
                            font-size: 20px;
                        }
                    }

                    .mb-cache-value {
                        margin-top: 6px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .cart-page__content .column-2 .pdp-accordion .order-sum__container {
        background-color: initial;
    }
}

.cart-layout .box-shdw-none .cart-prdct-tbl .tbs-cart-page-section .plus_icon .plus_image {
    margin-top: 26px !important;
}

.OD__body .hk-breadcrumb-cntnr {
    padding: 40px 0 20px;
    border-bottom: 1px solid rgba(8, 10, 8, .2);
}

.auth-error-mobl {
    color: #cb000b;
    font-size: 14px !important;
}

.hide {
    display: none;
}

.err_msg {
    @include fontStyle($fontFamily-default, 13px, $regular, 15px, 0px, red);
    margin-bottom: 5px;
    margin-top: -3px;
    clear: both;
    text-transform: capitalize;

    &.title {
        margin-top: 5px;
    }
}

.order-cancel__container .hk-breadcrumb-cntnr {
    padding: 40px 0 20px;
    border-bottom: 1px solid rgba(27, 40, 92, 0.2);
    margin-bottom: 20px;

    @media (max-width: 767px) {
        padding: 13px 0 10px;
        margin-bottom: 10px;
    }
}

.pay-later-container .pay-later .proceed-to-simpl .btn-blue-mini {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0;
    color: #FFF;
    background: #282960;
    border-radius: 2px;
    padding: 12px 0;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 1024px) {
        @include fontStyle($font-family-barlow, 16px, $medium, 21px, 0px, #FFF);
        background: #282960;
        padding: 12px 0;
        width: 163px;
        text-transform: uppercase;
    }
}

.checkout-flow .checkout-process .checkout-header .header-process-steps .steps .step .step-name {
    @media screen and (max-width: 768px) {
        margin-top: 6px;
        font-size: 12px;
        line-height: 15px;
    }
}

.checkout-flow .checkout-process .checkout-header .header-process-steps .steps .step .step-number {
    @media screen and (max-width: 768px) {
        width: 26px;
        height: 26px;
    }
}

.notifytext {
    color: unset;
    font-size: unset;
    float: unset;
    padding-left: unset;
    line-height: unset;
}

.soldouttext {
    position: unset;
    padding: unset;
    border: unset;
    border-radius: unset;
    background-color: unset;
    color: unset;
    display: unset;
    width: unset;
    margin-left: unset;
    font-size: unset;
    text-align: unset;
    right: 0;
    top: -65px;
}

.lwl__container h1,
.lwl__container h1 span {
    font-size: 50px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0px;
    color: #212121;
    text-align: center;
    margin: 0;

    @media (max-width: 767px) {
        font-size: 24px !important;
        line-height: 30px !important;
    }
}

.lwl__container h1 span {
    color: #ffc800
}

.lwl__women {
    h1 {
        margin-bottom: 38px !important;
    }
}

.lwl {
    .hk-swiper {
        .swiper-container {

            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                bottom: 25px;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: #080a08;

                &::after {
                    color: #000000;
                    content: "";
                    border: solid #fff;
                    border-width: 0 3px 3px 0;
                    display: inline-block;
                    padding: 5px;
                    transform: rotate(135deg);
                }
            }

            .swiper-button-prev {
                left: 60px;
            }

            .swiper-button-next {
                right: 65px;

                &::after {
                    transform: rotate(315deg);
                }
            }
        }
    }
}

.offers_desktop {
    .hk-elements--addToCart {
        padding-top: 10px;

        button {
            border: none;
            outline: none;
            background: #FFBE00;
            border-radius: 2px;
            height: 31px;
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0px;
            color: #20262E;
            min-width: 100px;
            border-radius: 2px;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 14px;
            justify-content: center;
            img{
                height: 11px;
            }
        }
    }

}

.offers_mweb {
    .hk-elements--addToCart {
        padding-top: 10px;

        button {
            border: none;
            outline: none;
            background: #FFBE00;
            border-radius: 2px;
            height: 31px;
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0px;
            color: #20262E;
            min-width: 100px;
            border-radius: 2px;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: center;
            img{
                height: 11px;
            }
        }
    }
}

//css for top-category module

.top-category-body-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
        column-gap: 8px;
        row-gap: 8px;
    }

    @media screen and (max-width: 360px) {
        .prodList{
            min-width: 0;
        }
    }
    @media screen and (max-width: 320px) {
        .prodList{
            min-width: 0;
            width: 100%;
        }
    }

    ._desktop_variant-container,
    .variant-container {
        // width: calc(100%/3 - (80px/3));
        width: calc(100%/2 - 10px) !important;
        //max-width: 270px;
        padding: 8px 11px 8px;
        border-radius: 4px;
        box-shadow: 0px -3px 30px #0000001A;
        // border: 1.8px solid #D1D4DE;

        .hk-variants-cta {
            padding: 9px 0px 0px 1px;
            border-top: 1px solid #ebecf1;
            display: flex;

            .hk-elements--buyNow {
                width: 50%;
                margin: 0;
                text-align: right;
                padding-right: 5px;

                button {
                    width: 100%;
                    border: 1px;
                    outline: none;
                    border: 0.8px solid #53566480;
                    background: transparent;
                    border-radius: 2px;
                    height: 40px;
                    font-family: "Roboto";
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0px;
                    color: #080A08;
                }
            }

            .hk-elements--addToCart {
                width: 50%;

                button {
                    width: 100%;
                    border: 0px solid #161616;
                    outline: none;
                    background: #FFBE00;
                    border-radius: 2px;
                    height: 40px;
                    font-family: "Roboto";
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0px;
                    color: #20262E;
                    min-width: 200px;
                    border-radius: 2px;

                    @media screen and (max-width: 1024px) {
                        min-width: 26px;
                        min-height: 26px;
                    }
                }
            }
        }

        .float-wrapper {
            display: flex;
            margin-bottom: 10px;
        }

        .cat-item-oos {
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            display: table;
            margin: 7px auto;
            width: 100%;
            padding: 10px 0px;
            margin-top: 17px;
            font-size: 15px;
            cursor: not-allowed;
            font-weight: 600;
            color: #aeb1bc;
            width: 90%;
            padding: 12px 0;
            border: 1px solid #aeb1bc;
            border-radius: 2px;
        }

        .sale {
            display: flex;
            margin-bottom: 10px;
        }

        .image-container {
            position: relative;
            text-align: center;
            min-width: 140px;
            max-width: auto;
            min-height: 100px;
            max-height: 180px;
            border-radius: 6px;
            // background-color: #FFFFFF;
            background-color: #F2F3F5;
            margin-right: 10px;

            .discount-content {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 40px;
                height: 40px;

                img {
                    width: 100%;
                    height: 100%;
                }

                .discount {
                    position: absolute;
                    top: 0px;
                    left: 8px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .text {
                        font-size: 14px;
                        font-weight: bold;
                    }

                    .sale {
                        font-size: 11px;
                        margin-top: -12px;
                    }
                }
            }

            img.main {
                // height: 100%;
                // position: absolute;
                // width: 100%;
                // height: 100%;
                top: 50%;
                left: 50%;
                // transform: translate(-50%, -50%);
                border-radius: 10px;
                width: 120px;
                height: 120px;
                background-color: inherit;
                mix-blend-mode: multiply;
            }

            .veg-nonveg-icon {
                height: 15px;
                width: 15px;
                position: absolute;
                bottom: 5px;
                left: 6px;
            }
        }

        .out-of-stock {
            @include fontStyle($fontFamily-default, 16px, 600, 20px, 0px, $color-primary);
            margin-top: auto;
            opacity: 0.3;
        }

        .details {
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            // justify-content: space-between;
            .info {
                @include fontStyle($fontFamily-default, 14px, 500, 20px, 0px, $color-primary);
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: 20px;
                opacity: 0.9;

                .name {
                    text-transform: uppercase;
                }

                .flavor-wt {
                    color: $color-primary;
                    opacity: 0.9;
                }
            }

            .flavor-wt {
                // color: $grey-clor;
                text-transform: none;
                @include fontStyle($fontFamily-bold, 14px, 400, 20px, 0px, $color-primary);
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                line-height: 24px;
                opacity: 0.9;
            }

            .price {
                align-items: center;
                position: relative;
                display: flex;
                gap: 3px;

                .actual-price {
                    @include fontStyle($fontFamily-default, 14px, 400, 12px, 0, $color-primary);
                    opacity: 0.7;
                    padding-top: 3px
                }

                .upto-price {
                    @include fontStyle($fontFamily-bold, 14px, 400, 22px, 0, #51B279);
                    padding-left: 5px;
                    padding-top: 3px;
                }

                .offer-price {
                    @include fontStyle($fontFamily-bold, 16px, 600, 12px, 0px, $color-primary);
                    // margin-left: auto;
                }
            }

            .rating-container {
                display: flex;
                gap: 10px;
                align-items: center;
                margin-top: 4px;

                .variant-rating {
                    width: 44px;
                    height: 20px;
                    background-color: #23A247;
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    p {
                        @include fontStyle($fontFamily-bold, 14px, 500, 21px, 0px, #fff);
                    }
                }
            }

        }

        .wishlist {
            align-self: flex-start;
            margin-left: auto;
        }
    }
}
.AYP__container{
    .title-wrap {
        .title{
            text-transform: capitalize;
        }
    }
}

.express-header{
    margin-left: auto;
    width: 70% !important;
    transform: translate(16%, 0%) !important;
}
//Top Categories side menu changes
// @media screen and (min-width: 1024px) {
.top-category-level2-parent-container{
    .category-side-menu-container{
        position: relative !important;
        width: 23% !important;
        margin-top: 9px !important;
        @media screen and (max-width: 1023px) {
            width: auto;
        }
        .top-category-side-menu-parent-container{
            width   : 250px;
            top     : 135px;
            position: sticky;
            bottom  : auto;
            @media screen and (max-width: 1023px) {
                width: 86px;
                top: 65px;
            }
        }
    }
}

.influancer-wrapper {
    .swiper-pagination-bullets{
        .swiper-pagination-bullet{
            width: 7px;
            height: 7px;
            background-color: #c3c3c3;
            opacity: 1;
        }

        .swiper-pagination-bullet-active{
            background-color: #000;
        }
    }
}

.no-bg-with-tranparent {

    .hk-swiper{
        .BS-Desk{
            background-color: transparent !important;

        }
    }
}
.lwl__container{
    .mobile{
        .banner-component{
            @media screen and (max-width:1023px) {   
            img{
                height: 243px;
            }
        }
        }
    }
}

.desktop-pdp-layout{
    .quantitySelector{
        .hk-elements--quantitySelector{
            .quantityCls{
                color: #ffffff;
                font-size: 22px;
                font-weight: 600;
            }
        }
    }
    .pdp-accordion{
        .pdp-accordion__title{
            padding: 18px 0;
        }
    }
}

.mobile-pdp-layout{
    .quantitySelector{
        .hk-elements--quantitySelector{
            .quantityCls{
                color: #ffffff;
            }
        }
    }
}

.vertical-menu-inner {
    .cart-prdct-tbl {
        padding: 0 !important;
        .tbs-cart-page-section{
            overflow-y: unset !important;
            background: none !important;
            .cart-item{
                background: none;
                border-bottom: unset !important;
                padding: 0 !important;
                margin: 0 !important;
                box-shadow: none !important;
                .cartitem-list{
                    display: flex;
                    padding: 15px 0 !important;
                }
                .cart-image{
                    margin: 0 15px 0 0;
                    .cart-image-side-menu{
                        padding: 0;
                        border-radius: 0;
                        background-color: #fff;
                    }
                    .cart-item-delete {
                        display: none;
                    }
                }
                .cart-item-name{
                    text-overflow: unset !important;
                    -webkit-line-clamp: unset !important;
                    margin: 0 !important;
                }
                .cart-item__flavour{
                    margin: 20px 0 0 0;
                }
            }
        }
    }
}

.shopfor-component{
    .shopfor-inner{
        .swiper-container{
            padding: 0 !important;
        }
    }
}

.tabswithvariant-component{
    .tabswithvariant-inner{
        .swiper-container{
            padding: 0 !important;
        }
    }
}

.BS-products{
    margin: 20px 0 0 0;
}
.main-card-container {
    .hk-swiper {
    .swiper-container {
        .swiper-wrapper {
            @media screen and (min-width:1024px) {
                display        : flex;
                justify-content: center;
            }
        }
        }
    }
}
.gritzo-india{
    .main-card-container{
        .hk-swiper{
            .swiper-container{
                .swiper-button-prev{
                    left: 117px !important;
                }
                .swiper-button-next{
                    right: 117px !important;
                }
            }
        }
    }
}
.BS-mob {
    .hk-swiper{
        .goal-section-container{
            .swiper-button-prev{
                left: -57px !important;
                @media screen and (max-width:1023px) {
                    display: none !important;
                }
            }
            .swiper-button-next{
                @media screen and (max-width:1023px) {
                    display: none !important;
                }
            }
            .swiper-wrapper{
                .swiper-slide{
                    @media screen and (min-width:1023px) {
                        margin :0px 11px;
                    }
                }
            }
        }
    }
}


.addtocartsticky{
    .quantitySelector{
        .hk-elements--quantitySelector{
            margin: 0;
        }
    }
}

.hideVDA{
    display: none;
}

.disabledVDA{
    // user-select: none;
    // pointer-events: none;
    // cursor: not-allowed;
}
.no-result {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2{
        padding: 0;
    margin: 42px 0 0 0;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: .03em;
    line-height: 44px;
    color: var(--color-text-second);
    @media screen and (max-width:1023px) {
        margin-top: 0px;
    }
    }
    span{
        margin-top: 2px;
        font-size: 15px;
        color: var(--color-text-third);
    }
    .go-to{
      background-color: #282960;
      border: 1px solid #282960;
      padding: 0.7rem 1rem;
      border-radius: 2rem;
      transition: 0.8s;
        margin-top: 34px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        width: 138px;
    height: 40px;
    }
}


.reviews-page{
    .hk-elements--quantitySelector{
        margin: 0 !important;
    }
}
.reviews-page-pdp-mob {
    .container {
        .inner-container {
            ._const_atc_buyNow {
                .hk-elements--addToCart {
                    button {
                        font-weight   : 500;
                        padding       : 16px 12px;
                        letter-spacing: 0;
                        color         : #c22c1f;
                        border-radius : 8px;
                        background    : #fff;
                        min-width     : 112px;
                        font-size     : 14px;
                        padding       : 6px 20px;
                        border        : 1px solid #001D42;
                        height        : 40px;
                    }
                }
            }

            .left {
                .quantitySelector {
                    .hk-elements--quantitySelector {
                        .quantityCls {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1023px) {
    .personalized{
        .box2{
            .hk-elements--buyNow {
                text-align: center;
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 999;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: 100%;
                padding: 0 20px;
                background: #fff;
            }
        }
    }
}
.hk-breadcrumb-cntnr-new-reviews-page{
    padding-top: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    flex-wrap: wrap;
      overflow: hidden;
      margin-top: 15px;
      margin-bottom: 0;
      white-space: nowrap;
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      overflow: auto;
      text-overflow: ellipsis;
      font-size: 13px;
      float: left;
      width: 100%;
      span{
        margin-right: 3px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #77777e;
      }
  }
  .gritzo-india{
    .main-card-container{
        .hk-swiper{
            .swiper-container{
                .swiper-wrapper{
                    @media screen and (max-width:1023px) {       
                        justify-content: center;
                    }
                }
            }
        }
    }
  }