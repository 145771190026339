@import "./global/normalize.scss";
@import "./global/global.scss";
@import "./global/variable.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  @include defaultText();
  @include font-family($fontFamily-default, "400");
  color: $color-primary;
}

img {
  white-space: nowrap;
  overflow: hidden;
}

.hk-header-wrapper {
  position: sticky;
  top: 0;
  z-index: 31;
}

/* global alignments */
.red-color {
  color: red !important;
}

.text-right {
  text-align: right !important;
}

.text-left,
.txt-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.margin-right-30 {
  margin-right: 30px;
}

.form-control {
  width: 100%;
  line-height: 51px;
  height: 51px;
  border: 1px $grey-2 solid;
  color: $black;
  padding: 0 16px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #F4F4F4;

  &.textarea {
    min-height: 100px;
    height: auto;
  }

  &:disabled {
    // color: $black;
    // background: $grey-11;
  }
}

.authForm {
  border: red 1px solid;
}

.button-container {
  display: flex;
  position: relative;
  right: -64%;
  margin: 30px 0px 20px 0px;

  .btn-save,
  .discord {
    background: #001D42;
    color: #FFF;
    font-size: 16px;
    line-height: 21px;
    border: 1px #001D42 solid;
    border-radius: 10px;
    text-align: center;
    padding: 13px 68px;
    cursor: pointer;
    margin-right: 20px;
  }

  .discord {
    background-color: white;
    border: 1px solid #001d42;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    color: #001d42;
  }
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  clear: both;

  .no-margin {
    margin: 0px;
  }

  label,
  .form-label {
    display: block;
    font-size: 14px;
    color: $black2;
    line-height: 32px;
    text-align: left;
    font-weight: $medium;

    .forgot-pwd-desk {
      position: absolute;
      right: 2%;
      top: 43px;
    }
  }

  .gender-container {
    width: 100%;
    margin: 0px 0 15px 0;

    .gender-text {
      width: 100%;
      font-weight: 600;
    }

    .gender-types {
      width: 100%;
      margin: 10px 0;

      .gender-type-tb {
        border: 2px solid #ccd6d7;
        border-radius: 5px;
        padding: 14px 15px 10px;
        display: inline-block;
        margin: 0 20px 0 0;
        // height: 40px;
        min-width: 107px;
        text-align: center;
        line-height: 14px;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        .gt-name {
          color: #505050;
          text-transform: capitalize;
        }

        &.selected-gender {
          border-color: #fec710;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

          .gt-name {
            font-weight: 600;
          }
        }
      }
    }
  }

  .change-pass-abs {
    position: absolute;
    top: 45px;
    right: 16px;
    color: $black2;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    &.black2 {
      color: $black2;
      border-bottom: 1px $black2 solid;
      top: 14px;
    }

    &.black {
      color: $black2;
      border-bottom: 1px $black2 solid;
    }
  }

  .err-mssg {
    color: red;
    font-weight: 500;
    font-size: 14px;
  }
}

.form-label {
  display: block;
  font-size: 14px;
  color: $black2;
  line-height: 32px;
  text-align: left;
  font-weight: $medium;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute;
}

.input-addon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);

  &.border {
    border: 1px $grey-2 solid;
  }

  .form-control {
    border: none;
    box-shadow: none;
  }
}

/* page  layout */
.inline-block {
  display: inline-block !important;
}

.fr {
  float: right;
}

a,
.cursor-pointer {
  cursor: pointer !important;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.cta-disabled {
  // opacity: 0.6;
  pointer-events: none;
  background: #dbdee8 !important;
  border: 1px solid #dbdee9 !important;
  border-radius: 4px !important;
  color: #FFF !important;
  pointer-events: none;


  button {
    background: #dbdee8 !important;
    border: 1px solid #dbdee9 !important;
    border-radius: 4px !important;
    color: #a4a4a8 !important;
    pointer-events: none;

    @media screen and (max-width: 1024px) {
      padding: 5px 16px !important;
    }

  }
}

.block,
.display-block {
  display: block !important;
  width: 100% !important;
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;

  .quantity-selector {
    margin-right: 10px;

    @media screen and (max-width: 1024px) {
      margin-top: 14px
    }
  }
}

.flex-v-center {
  @extend .flex;
  align-items: center;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-space-between {
  @extend .flex;
  justify-content: space-between;
}

.flex-end {
  @extend .flex;
  justify-content: flex-end;
}

.flex-start {
  align-items: flex-start;
}

.flex-center {
  align-items: center;
}

.flex-v-h-center {
  @extend .flex;
  align-items: center;
  justify-content: center;
}

.flex-v-h-start {
  @extend .flex;
  align-items: center;
  justify-content: flex-start;

}

.HK-pagecontainer,
.container-wrap {
  width: 100%;
  max-width: 1200px;
  float: none;
  margin: 0 auto;
  clear: both;

  @media screen and (min-width: 769px) {
    padding: 0 15px;
  }
}

#mb-loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  display: none;
  position: fixed;
  background: rgb(238, 238, 238);
  opacity: 0.5;
  width: 100%;
  height: 100%;

  div {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: 2px solid #00bfbf;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    @include animation(loader_default 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8));
  }

  &.show {
    display: block;
  }
}

.mb-btn-login {
  justify-content: center !important;
}

.mb-btn {
  border-radius: 2px;
  outline: none;
  text-align: center;
  padding: 8px 29px;
  display: inline-block;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: auto;
  height: 40px;
  font-family: $fontFamily-oswald;
}

button {
  &:disabled {
    opacity: 0.5;
  }
}

.btn {
  border-radius: 4px;
  outline: none;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  display: inline-block;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: auto;
  height: 48px;
  line-height: 48px;
  font-weight: $medium;

  &.semibold {
    font-weight: $semibold;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.border-radius-50 {
    border-radius: 50px;
  }

  &.btn-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.font-18 {
    font-size: 18px;
  }
}

body.no-overflow {
  overflow: hidden;
}

.page-layout-desktop {
  float: left;
  width: 100%;
  // padding-top: 70px;
}

/* accordion  */
.accordion-wrap {
  .accordion-item {
    border: 1px $grey-2 solid;
    box-shadow: 4px;
    margin-bottom: 20px;
    float: left;
    width: 100%;

    &.final {
      border: 0;
      padding-top: 40px;
    }

    .accordion-heading {
      @include background-opacity($grey-15, 0.86);
      padding: 10px 20px;
      position: relative;
      cursor: pointer;
      align-items: center;

      .title {
        text-transform: uppercase;
        font-weight: $medium;
      }

      &>* {
        color: $black2;
        font-size: 16px;
      }

      .order-deatil-text {
        color: $orange3 !important;
        font-weight: $semibold !important;
        font-size: 22px;
      }

      .accordion-action-btn {
        background: $black2;
        color: $white;
        height: 30px;
        line-height: normal;
        font-size: 14px;
        border: 1px transparent solid;
        min-width: 85px;
      }

      &.final {
        background: white;
        padding-top: 30px;
        border: 0;
        box-shadow: 0 -3px 10px 6px $grey-38;

        .title,
        .order-deatil-text {
          color: $black2 !important;
          font-size: 14px;
          font-weight: $bold;
          text-transform: uppercase;
        }
      }
    }

    .accordion-content {
      color: $black2;
      padding: 10px 20px;

      &.hidden {
        display: none;
      }

      &.visible {
        display: block;
      }

      &.no-padding {
        padding: 0px;
      }

      .shipping-addresss {
        .payment-layout .HK-pagecontainer {
          padding: 0px;
        }

        &>.item {
          padding: 20px 10px;
          font-size: 16px;
          border-top: 1px $grey-2 solid;
          padding-left: 35px;
          position: relative;

          .address-select-radio {
            position: absolute;
            left: 7px;
            top: 21px;
          }

          &.active {
            @include background-opacity($grey-15, 1);
          }
        }
      }
    }
  }
}

.deliveryBtn {
  padding: 0px 30px;
}

.no-padding {
  padding: 0px !important;
}

/* loader */
.show-loader {
  position: relative;
}

.show-loader::before,
.show-loader::after {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.show-loader::before {
  background: #df4723;
}

.show-loader::after {
  margin: auto;
  z-index: 2;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #00bfbf;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.invisible {
  visibility: hidden !important;
}

.form-group {
  p.err-txt {
    position: absolute;
    top: 100%;
    left: 1px;
    margin-top: -1px;
  }
}

.width-100-n {
  width: 100px;
}

.row-same-height {
  @extend .flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  position: relative;

  a {
    display: inline;
  }

  &.now-wrap {
    flex-wrap: nowrap;
  }

  .col-same-height {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;

    .content {
      width: 100%;
    }
  }
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-xs-5 {
  width: 41.66666667%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-7 {
  width: 58.33333333%;
  padding-left: 15px;
  padding-right: 15px;
}

/* bullet  radio buttons style starts*/
.isBullet {
  padding-left: 30px !important;
}

.bullet {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 13px;
  cursor: pointer;

  &.open {
    background: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/Bullet-round.svg) center center no-repeat;
  }

  &.solid {
    background: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/Bullet-solid.svg) center center no-repeat;
  }

  &.tick {
    border: 1px $black2 solid;
    background: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/Bullet-tick.svg) center center no-repeat;
  }
}

/* bullet radio button style ends */
.delete-address {
  display: inline-block;
  width: 75px;
  text-align: center;
  color: $black-1;
  border: 1px solid $black-1;
  border-radius: 4px;
  font-size: 14px;
}

.row-action {
  margin-top: 10px;

  .btn {
    margin-right: 10px;
  }
}

.radioFilled,
.radioEmpty {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-size: 100% auto;
}

.radioFilled {
  background: white;
  position: relative;
  border-radius: 100%;
  border: 3px $yellow2 solid;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: $black2;
    content: "";
  }
}

.radioEmpty {
  background: white;
  border: 3px $grey-10 solid;
  border-radius: 100%;
}

/*Modal Dialog  */
.hk--error-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(150, 150, 150, 0.7);
  z-index: 9999999999 !important;
  height: 100%;
  width: 100%;

  .hk--error-popup-content {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    box-shadow: 0 3px 10px 2px #666;
    background: white;
    max-height: 97%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &.add-address-content {
      max-width: 578px;
      width: 100%;
    }

    &.review-image-gallery {
      width: 80%;
    }

    &.width-70 {
      width: 70%;
    }

    .hk--error-popup-title {
      display: inline-block;
      padding: 10px 30px 10px 10px;
      vertical-align: middle;
      font-size: $fontSize-headingSmall2;
      width: 100%;
      position: relative;
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 10px;
      background: #272b65 !important;

      span {
        color: $white;
        font-size: 16px;
      }

      img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }

      &.bg-white {
        background: $white !important;

        span {
          color: $black;
          font-size: 22px;
          line-height: 50px;
        }
      }

      &.bg-grey-shadow {
        background: linear-gradient(to bottom, #f7f7f7, #eaeaea) !important;
        border-radius: 4px 4px 0 0;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset, 0 -1px 0 rgba(255, 255, 255, 0.4) inset;
      }
    }

    .hk--error-popup-body {
      background: #fff;
      display: block;
      padding: 15px 10px;
      font-size: $fontSize-default;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .remove-Bottom-Padding {
      padding: 0px !important;
      border-radius: 16px;
    }
  }

  .add-Border-Radius {
    border-radius: 16px;
  }
}

#HK_TOAST {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 10px 15px;
  position: fixed;
  z-index: 9999;
  left: 50%;
  transform: translateX(-50%);
  bottom: 72px;
}

#HK_TOAST.show {
  visibility: visible;
  animation: snackbar_fadein 0.5s, snackbar_fadeout 0.5s 2.5s;
  -webkit-animation: snackbar_fadein 0.5s, snackbar_fadeout 0.5s 2.5s;
  -moz-animation: snackbar_fadein 0.5s, snackbar_fadeout 0.5s 2.5s;
  -o-animation: snackbar_fadein 0.5s, snackbar_fadeout 0.5s 2.5s;
}

.hk--error-popup .hk--error-popup-content .hk--error-popup-title {
  background: #fff1f2;
}

/* Address Page Style starts  here */
.geo-label {
  &>span {
    display: flex;
    align-items: center;

    svg {
      fill: $orange4;
    }

    span {
      margin-left: 10px;
      color: $orange4;
    }
  }
}

.default-address {
  color: $black2;
  background: $grey-29;
  font-size: 14px;
  line-height: normal;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 4px;
}

/* Address Page Style ends  here */
// a:focus,
// a:hover,
// button:focus,
// button:hover {
//   color: #333;
//   text-decoration: none;
//   outline: 0;
// }

/* Button styling */
.btn-yellow {
  background: #282960 !important;
  color: $color-white !important;
  // &:hover{
  //   background: $color-white !important;
  //   color: $gz-sky-blue !important;
  //   transition: .5s;
  // }
}

.btn-yellow2 {
  background: $gz-sky-blue;
}

.btn-black2 {
  background: $black2;
  color: $white;
  border: 1px transparent solid;

  &:hover {
    color: $white;
  }
}

.btn-black2-border {
  background: transparent;
  color: $black2;
  border: 1px $grey-10 solid;

  &:hover {
    color: $black2;
  }
}

.width-100 {
  width: 100% !important;
}

.col-span-row {
  display: flex;
  margin: 0 -15px;

  .col-spn-cell {
    padding: 0 15px;
  }
}

.col-sm-6 {
  width: 50%;
  padding: 0 15px;
}

.address-action-btn {
  width: 112px;
  margin: 26px 20px 0 0;
  height: 46px;
  line-height: 46px;

  &:last-child {
    margin-right: 0;
  }
}

.row-action.no-margin {
  .address-action-btn {
    margin: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.name-phone-container {
  .desc {
    color: $black2;
    font-size: 16px;
  }
}

.address-list {
  display: flex;
  align-items: flex-start;
  width: 100%;

  .make-default {
    margin-left: 50px;
  }
}

.make-default {
  color: $black2;
  border-bottom: 1px $black2 solid;
  display: inline-block;
  padding: 0 4px;
}

.selct-add-section {
  padding: 20px 0;
  float: left;
  width: 100%;
  border-bottom: 1px solid $fontColor-grey5;

  .add-new-add {
    width: 245px;
    height: 50px;
  }
}

.gender-wrap {
  display: flex;
  align-items: center;

  &>div {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  label {
    margin-left: 10px;
    font-weight: normal;
    text-transform: capitalize;
  }
}

.edit-link {
  color: $black2;
  font-size: 21px;
  line-height: 32px;
  border-bottom: 1px $black2 solid;
  cursor: pointer;
}

.flex-space-between .edit-link {
  margin-right: 15px;
}

.order-summary-heading {
  margin-top: 35px;
  float: left;
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.ml-15 {
  margin-left: 15px;
}

.continue-shopping-dsk {
  a {
    float: left;
    width: 100%;
    padding: 0 13px;
    text-align: center;
    background-color: transparent;
    border: 1px solid $primary-color;
    color: #fff;
    background: $primary-color;
    border-radius: 4px;

    b {
      font-weight: 700;
      color: #fff;

      img {
        height: 12px;
        margin-right: 5px;
      }
    }

    @media screen and (min-width:1024px) {
      background: #fff;

      b {
        color: $primary-color;
      }
    }
  }
}

.pad-l-r-15 {
  padding: 0 15px !important;
}

body .address-layout .accordion-content {
  .selct-add-section {
    border-radius: 0;
    border-bottom: 0;
  }
}

.no-border {
  border: 0px !important;
}

.menu-back-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;

  // visibility: hidden;
  // opacity: 0;
  // transition: all 0.5s ease;
  &.white {
    background: rgba(0, 0, 0, 0.4);
  }

  &.black {
    background: rgba(0, 0, 0, 0.5);
  }

  &.display-menu {
    visibility: visible;
    opacity: 1;
    left: 0;
  }
}

a,
button {
  cursor: pointer;
}

.order-detail-arrow {
  margin-left: 10px;

  &.arrow-up {
    -webkit-transform: rotate(180deg);
    /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg);
    /* FF */
    -o-transform: rotate(180deg);
    /* Opera */
    -ms-transform: rotate(180deg);
    /* IE9 */
    transform: rotate(180deg);
    /* W3C compliant browsers */
  }
}

.vertical-menu-wrap {
  // display: none;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  min-width: 305px;
  height: 100%;
  overflow: hidden;
  border: 1px #ddd solid;
  background: $white;
  z-index: 999999;
  transition: all 0.5s ease;
  box-shadow: 0 0px 15px #00000029;

  &.bg-gray {
    @include background-opacity($grey-15, 0.84);
  }

  &.fromRight {
    right: -100%;

    &.display-menu {
      visibility: visible;
      opacity: 1;
      right: 0;
      overflow-y: auto;
    }
  }

  &.fromLeft {
    left: -100%;

    &.display-menu {
      visibility: visible;
      opacity: 1;
      left: 0;
    }
  }

  .vertical-menu-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .vertical-menu-content {
    flex: 2 0 auto;
    position: relative;

    .menu-actual-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      .cart-layout {
        .no-prod-widget {
          .no-data-container {
            .cart-img {
              .cart-icon {
                width: 100px;
              }

              .continue-cart-btn {
                width: 100px;

                .btn-otp-req {
                  border: none;
                  @include fontStyle($fontFamily-default, 14px, $medium, 18px, 0px, #001d42);
                }
              }
            }
          }
        }

        //This changes for side cart item menu on pdp page 
        .tbs-cart-page-section {
          .cart-pack-item {
            .cartPackChild {
              .combo-header {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

.profile-menu {
  padding: 0 15px;

  .vertical-menu-header {
    height: 100px;
  }

  .vertical-menu-footer {
    padding: 10px 0;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    left: 10px;
  }

  .welcome-msg {
    color: $black2;
  }

  .user-name {
    color: $black2;
    font-weight: $semibold;
    margin: 5px 0 20px 0;
  }

  .menu-item {
    display: inline-block;

    &>li {
      border-bottom: 1px $grey-10 solid;

      &:last-child {
        border: 0;
      }

      &>a {
        color: $black2;
        display: block;
        margin: 10px 0;
      }
    }
  }
}

.sign-out-btn {
  min-width: 200px;
  font-size: 25px;
}

.hk_delivery_date {
  margin-top: 5px;

  span {
    font-size: 12px;
  }

  &* {
    color: #2e2e2e;
    font-size: 12px;
    font-weight: $medium;
  }
}

//desktop-cartmenu
.cart-menu {
  width: 100%;
  max-width: 161px;
  min-width: 161px;
  top: 76px;
  filter: drop-shadow(-10px 4px 16px #0000000d);
  box-shadow: none;

  &.checkout-screen {
    // display: none;
  }

  &.promotext {
    top: 122px;

    .cart-prdct-tbl {
      .tbs-cart-page-section {
        max-height: calc(100vh - 240px) !important;
      }
    }

    .order-summary-widget {
      .right-menu-footer {
        top: calc(100vh - 190px) !important;
      }
    }
  }

  .vertical-menu-inner {
    padding-bottom: 72px;

    .vertical-menu-header {
      .menu-heading {
        padding: 0 10px 0 0;
        text-align: right;

        .close-btn {
          margin: 0;
          width: 15px;
        }
      }
    }

    .vertical-menu-content {
      .cart-prdct-tbl {
        padding: 0 10px;

        .tbs-cart-page-section {
          padding: 0;
          overflow-y: auto;
          max-height: calc(100vh - 196px);
          @include scrollbar-thin;

          .cart-item {
            border-bottom: 1px solid rgba($color: #1b285c, $alpha: 0.2);
            padding: 10px 0 0;
          }

          .cartitem-list {

            &:not(:first-child) {
              padding-top: 9px;
            }

            .cart-image {
              position: relative;
              display: flex;

              .prod-image {
                width: 79px;
              }
            }

            .cart-item-delete {
              position: absolute;
              bottom: 0;
              right: 5px;
              cursor: pointer;
            }

            .cart-item-name {
              margin-top: 6px;
              @include fontStyle($fontFamily-oswald, 12px, 500, 14px, 0px, $header-background);
              // text-transform: uppercase;
              height: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              .cart-item__flavour {
                @include fontStyle($fontFamily-oswald, 12px, 500, 14px, 0px, #0085cb);
                text-transform: uppercase;
              }
            }
          }

          .plus_icon {
            .plus_image {
              width: 30px;
              height: 50px;
              margin-top: 0;
              margin-left: 0;
              top: 5px;
              left: -7px;
            }

            p.text {
              display: none;
            }
          }
        }
      }

      .order-summary-widget {
        .right-menu-footer {
          max-width: 161px;
          background: #20262E;
          box-shadow: none;
          text-align: center;
          padding: 9px 0 0;
          top: calc(100vh - 144px);

          .cart-total-detail {
            justify-content: center;
            margin-bottom: 15px;

            .sub-total {
              @include fontStyle($fontFamily-default, 10px, 400, 14px, 0px, #FFFFFF);
            }

            .amount {
              margin-left: 7px;
              @include fontStyle($font-family-barlow, 12px, 500, 14px, 0px, #FFFFFF);
            }
          }

          .discount-text {
            display: none;
          }

          .checkout-btn {
            @include fontStyle($font-family-barlow, 12px, 500, 14px, 0px, #20262E);
            padding: 8px 24px;
            width: auto;
            background: #FFBE00;
            border-radius: 5px;
            height: auto;
            max-width: 130px;
            text-align: center;
            align-items: center;
            text-transform: uppercase;
            margin-bottom: 10px;

          }
        }
      }
    }
  }

  .right-menu-footer {
    // padding-left: 20px !important;
    // padding-right: 20px !important;
    // max-width: 161px;
  }

  &.no-footer {
    .vertical-menu-inner {
      padding-bottom: 10px;
    }
  }

  .menu-heading {
    background: $white;
    padding: 0 45px 0 15px;

    h3 {
      color: $black2;
      font-weight: $semibold;
      font-size: 20px;
      border-bottom: 1px solid #f2f2f2;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .close-btn {
      margin-left: 20px;
      vertical-align: middle;
    }
  }

  &.bg-gray {
    .menu-heading {
      background: transparent;
    }
  }

  &.checkout-layer {
    position: absolute;
    min-height: 100%;
    height: auto;
    border-top: 0px;

    .vertical-menu-header {
      position: fixed;
      top: 0;
      right: 1;
      width: 100%;
      max-width: 497px;
      z-index: 999999;
      padding: 0px 15px;
      background: white;
      border-bottom: 1px solid #f2f2f2;

      .menu-heading {
        padding: 0px;

        h3 {
          border: 0;
        }
      }
    }

    .menu-actual-content {
      position: static;
      padding-top: 62px;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.close-btn {
  cursor: pointer;
}

.default-heading {
  @include defaultHeading();
}

.outline-btn {
  padding: 0px 15px;
  line-height: 35px;
  border: 1px solid $grey-5;
  border-radius: 50px;
  background: $white;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: $black;
  cursor: pointer;
  display: inline-block;
  color: $black;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    border: none;
    letter-spacing: 1.82px;
    line-height: 21px;
    font-weight: 700;
    color: $orange;
    height: auto;

    svg {
      width: 15px;
      height: 12px;
      fill: $orange;
      margin-left: 12px;
      display: inline-block;
    }
  }
}

.width_auto,
.width_auto.flex {
  width: auto;
  @media screen and (min-width: 1023px) {
  display: flex;
  align-items: center;
  }
}

.pos-static {
  position: static !important;
}

.category-page {
  .vegnonveg {
    margin-top: 10px;
  }
}

.vegnonveg {
  .vegnonveg--icon {
    float: left;
    margin: 0 7px 0 0;
    border: 2px solid #000;
    width: 18px;
    height: 18px;
    border-color: #22b573;
    margin-right: 12px;

    .dot {
      border-radius: 50%;
      background: #000;
      width: 10px;
      height: 10px;
      margin: 2px;
      background: #22b573;
    }

    &.veg {
      border-color: #22b573;

      .dot {
        background: #22b573;
      }
    }

    &.nonVeg {
      border-color: #ff1d25;

      .dot {
        background: #ff1d25;
      }
    }
  }
}

.padding-x-20 {
  @media screen and (min-width: 1024px) and (max-width: 1232px) {
    padding: 0 20px;
  }
}

.float-wrapper {
  float: left !important;
  width: 100% !important;
}

.order-summary .order-summary-wrapper {
  padding: 0 15px;
}

.yousave-checkout {
  background: $white !important;
  font-size: 16px;
  border-radius: 40px;
  padding: 0 15px;
  line-height: 50px;
  margin-top: 5px;
  text-align: center;
}

.fnt-bolder {
  font-weight: $semibold;
}

.col-row {
  margin: 0 -8px;
}

.col-row-15 {
  margin: 0 -15px;
}

.checkout-desc {
  color: $grey-13;
  font-size: 14px;
  line-height: 16px;
  margin-top: 20px;
}

.hk-breadcrumb-cntnr {
  margin-top: -10px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  float: left;
  width: 100%;

  &.prod-rad {
    // margin-top: 20px;
    height: 50px;

    @media screen and (max-width: 768px) {
      background-color: #eeeeee;
    }
  }

  &>span {
    &:after {
      content: '\003e';
      margin-right: 2px;
      color: #bcbfc2;
      height: 30px;
    }

    &:last-child {
      &:after {
        content: "";
        display: none;
      }

      // span {
      //     color: #515455;
      //     font-weight: 600;
      // }
    }
  }

  span {
    margin-right: 5.5px;
    font-size: 12px;
    color: #929495;
  }
}

.continue-cart-btn {
  max-width: 320px;
  text-align: center;
  margin: 20px auto 0px auto;
}

.width-auto {
  width: auto !important;
}

.width-230 {
  max-width: 230px !important;
}

.ovfl-y-hid {
  overflow-y: hidden;
  height: 100%;
}

.ovfl-x-hid {
  overflow-x: hidden;
}

.content-wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0px auto;
}

.utility-component {
  width: 100%;
  float: left;
  position: relative;

  * {
    z-index: 11;

    @media screen and (max-width:768px) {
      z-index: 5;
    }
  }

  // @media screen and (max-width: 1024px) {

  //   Appbar
  .open-appbar {
    // position           : fixed;
    // bottom             : 23px;
    // left               : 0;
    // z-index            : 3;
    // width              : 100%;
    // background: transparent;
    // background-repeat  : no-repeat;
    // background-size    : cover;
    // height             : 60px;
    // border-radius: 10px;
    // width: 36%;
    // /* height: 200px; */
    // margin: 0 auto;
    // padding: 10px;
    // right: -60%;
    // @media screen and (min-width: 1024px) {
    //   right: -88%;
    //   width: 11%;
    //   bottom: 9px;
    // }

    position: fixed;
    right: 15px;
    bottom: 15px;
    // height: 60px;
    // border-radius: 10px;
    z-index: 3;
    // width: 100px;
    .appbar {
      // @include font(14px, 600, 22px, 0.22px, #18BFBF);
      @include fontStyle($fontFamily-oswald, 12px, 500, 14px, 0px, #FFF);
      text-transform: capitalize;
      cursor: pointer;
      // margin: 4px 0px;
      padding: 0px;
      border-radius: 6px;
      padding: 10px;
      // margin: 4px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 64px;
        height: auto;
      }
    }

    .close-appbar {
      cursor: pointer;
      float: right;
      margin: 0;
      // position: absolute;
      right: 2%;
      top: 18px;
      display: none;
    }

    .icon {

      img {
        margin: 0px 10px;
        background-color: #fff;
        border-radius: 26px;
      }
    }

    .open-app-content {

      // display: flex;
      // justify-content: space-between;
      .icon {}

      .open-app-text {
        padding: 0px 11px;

        .top-text {
          @include fontStyle($fontFamily-oswald, 12px, 600, 18px, 0px, #FFFFFF);
        }

        .bottom-text {
          @include fontStyle($fontFamily-oswald, 12px, 400, 20px, 0px, #FFFFFF);
        }
      }
    }
  }
}

.addition-top{
  bottom: 50px !important;
}

// }

.express-order-summary {
  float: left;
  width: 100%;
  background: $grey-45;

  .order-summary-widget,
  .cart-sum-tblinner,
  .cart-summary {
    float: left;
    width: 100%;
  }

  .address-data {
    border: 1px solid $grey-11;
    background: $white;
    padding: 20px 15px;
    margin-bottom: 10px;
    float: left;
    width: 100%;
  }

  .order-summary-list {
    border: 1px solid $grey-11;
    background: $white;
    padding: 20px 15px;
    margin: 10px 0;
    float: left;
    width: 100%;
  }

  .delivery-address {
    display: flex;
    align-items: flex-start;
  }
}

.non-shippable-action {
  float: left;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 10px;

  .btn-blue-mini {
    background: #393939;
    color: #fff;
    height: 30px;
    font-size: 14px;
    border: 1px transparent solid;
    min-width: 85px;
    line-height: 30px;
    margin-right: 10px;

    &:hover {
      color: #fff;
    }
  }
}

.page-layout-mob {
  // padding-top: 76px;

  .payment-layout,
  .non-shippable {
    padding-top: 70px;
  }

  .non-shippable-action {
    float: left;
    width: 100%;
    background: $grey-45;
  }
}

.update-email {
  max-width: 400px;
}

.checkbox {
  width: 100%;
  position: relative;
  display: flex;

  label {
    width: 100%;
    padding-left: 25px;
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }
  }

  span.checboximg {
    position: absolute;
    left: 0;
    top: 1px;
    width: 16px;
    height: 16px;

    &:before {
      background: $white;
      border: 1px $grey-10 solid;
      @include set-box-shadow(0, 3px, 6px, $grey-31);
      padding: 7px;
      box-sizing: border-box;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 10px;
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      content: "";
    }
  }

  input[type="checkbox"]:checked+span:after {
    content: "\2714";
    border: 2px $black solid;
    font-size: 12px;
    position: absolute;
    top: 3px;
    left: 0px;
    color: $black;
    background: $yellow2;
    line-height: 14px;
    width: 14px;
    height: 14p;
    text-align: center;
    border-radius: 10px;
  }

  &.ellipsis {
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.right {
    label {
      display: block;
      padding-right: 25px;
      padding-left: 0;
    }

    span.checboximg {
      position: absolute;
      right: 1px;
      left: auto;

      &:before {
        left: 0;
        content: "";
      }
    }

    input[type="checkbox"]:checked+span:after {
      left: 0px;
    }
  }
}

.radioBox {
  width: 100%;

  label {
    padding-left: 25px;
    position: relative;
    display: block;
    width: 100%;

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }
  }

  span.checboximg {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;

    &:before {
      background-color: #fff;
      border: 1px #707070 solid;
      border-radius: 50px;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 100%;
      height: 100%;
      top: 0px;
      right: 0px;
    }
  }

  input[type="radio"]:checked+span:before {
    content: " ";
    border: 1px solid #fec710;
    color: #99a1a7;
  }

  input[type="radio"]:checked+span:after {
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    background: #fec710;
    top: 4px;
    left: 2px;
  }

  &.right {
    label {
      padding-right: 15px;
      padding-left: 0;
    }

    span.checboximg {
      position: absolute;
      right: 0;
      left: auto;
    }
  }

  &.ellipsis {
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.paymentAuthComp {
  width: 100%;
  text-align: center;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

* html .clearfix {
  zoom: 1;
}

/* IE6 */
*:first-child+html .clearfix {
  zoom: 1;
}

/* IE7 */
.variant-offer-pr {
  font-family: $fontRoboto;
}

.variant-mrp {
  text-decoration: line-through;
  color: $grey-37;
  font-family: $fontRoboto;
}

/* lab -report */
.lab-report-section {
  padding: 50px 0;

  h2 {
    font-size: 28px;
    font-weight: $bold;
    margin-top: 0;
  }

  .lab-report-list li {
    color: #bcbcbc;
    margin-top: 10px;
    float: left;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    position: relative;
  }

  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-collapse: collapse;

    th,
    td {
      border: 0;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 12px;
      text-align: left;
    }

    th {
      font-weight: $semibold;
      color: $black;
    }

    td {
      color: $black2;

      .view-report {
        font-weight: $medium;
        text-decoration: underline;
      }
    }
  }
}

.vertical-menu-wrap.category-filter-menu.display-menu {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.vertical-menu-wrap.category-filter-menu {
  width: 100%;
  max-width: 100%;
  visibility: visible;
  opacity: 1;
  background: transparent;
  z-index: 999;

  .vertical-menu-inner {
    padding: 53px 0 80px 0;
    background: $grey-0;
  }

  .menu-actual-content {
    background: $grey-0;
  }
}

.category-menu-list {
  width: 35%;

  li.menu-item-wrap {
    .category-mega-menu {
      position: absolute;
      top: 0;
      right: 0;
      width: 65%;
      min-height: 100%;
      background: white;
      padding: 15px;
      visibility: hidden;
      opacity: 0;

      .image-wrap {
        text-align: left;

        a {
          display: block;
        }

        img {
          // max-height: 100px;
          width: auto;
          max-width: 100%;
        }

        margin-bottom: 10px;
      }

      .menu-heading {
        color: $black12;
        font-weight: $medium;
        font-size: 18px;
        text-align: left;
      }

      .cat-menu-l1 {
        li {
          border-radius: 2px;
          box-shadow: 0 0px 15px $black9;
          padding: 15px 0px;
          margin: 10px 5px;

          a {
            position: relative;
            width: 100%;
            justify-content: space-between;
            display: flex;
            align-items: center;
          }

          a>span.menu-content {
            color: $black7;
            font-size: 13px;
            font-weight: $medium;
            line-height: normal;
            display: flex;
            align-items: center !important;

            img {
              max-width: 50px;
              display: inline-block;
              margin-right: 10px;
              margin-top: 6px;
            }

            svg {
              vertical-align: middle;
            }
          }
        }
      }
    }

    a.menu-item {
      color: $black7;
      font-size: 13px;
      line-height: normal;
      padding: 10px 5px 10px 10px;
      position: relative;
      width: 100%;
      font-weight: $medium;
      text-transform: capitalize;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover,
      &.active,
      &:hover a {
        border-left: 5px $yellow5 solid;
        background: $white;
        font-weight: $bold;
      }
    }

    &.active .category-mega-menu {
      visibility: visible;
      opacity: 1;
    }
  }
}

.action-footer.home {
  position: fixed;
  bottom: 0;
  z-index: 9991 !important;
  width: 100%;
  background: #f8f7f5;
  box-shadow: 0 -3px 6px #0000001c;
  padding: 0 10px;

  .action-bar {
    text-align: center;
    padding: 0 !important;
    margin: 0;
    max-height: 80px;
    display: flex;
    justify-content: space-between;
    font-size: medium;
  }

  .action {
    border: 0;
    height: 100%;
    padding: 8px 5px;
    margin-right: 0;
    color: $black17;
    line-height: 1;
    border-bottom: 2px transparent solid;

    &.active {
      border-bottom: 2px $yellow5 solid;
      background: $white;
      font-weight: $bold;
    }

    .svg-url {
      width: 22px;
      height: 22px;
      display: flex !important;
      width: 100% !important;
      justify-content: center;

      &.home-icon {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/home-icon.svg) center center no-repeat;
        background-size: auto 100%;
      }

      &.cat-icon {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/cat-icon.svg) center center no-repeat;
        background-size: auto 100%;
      }

      &.auth-icon {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/auth-icon.svg) center center no-repeat;
        background-size: auto 100%;
      }

      &.account-icon {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/user-icon.svg) center center no-repeat;
        background-size: auto 100%;
      }
    }
  }

  .action span {
    display: block;
    line-height: 21px;
  }

  .action .svg-url {
    width: 25px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
  }

  .action.js-sort-action.actionSortNewClick .svg-url {
    background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/category/sort.svg);
  }

  .action.js-filter-action.filter-sec.actionFilterNewClick .svg-url {
    background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/category/filter.svg);
  }
}

.menu-arrow {
  .a {
    fill: none;
  }

  .b {
    fill: $orange;
  }
}

.bg-white {
  background: $white !important;
}

.cart-prdct-tbl {
  float: left;
  width: 100%;
}

.clipped-grey-bg {
  padding: 60px 0;
  clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
  background-color: $grey-0;
}

.pdp-action-button-container {
  &.review_sec {
    @extend .flex;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      border-radius: 5px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      width: 200px;
      height: 40px;

      img {
        margin-right: 7px;
      }
    }

    .hk-elements--addToCart button,
    .hk-elements--buyNow button {
      &:hover {
        font-size: 12px;
      }
    }

    .hk-elements--addToCart {
      margin-right: 10px;
      border-radius: 2px;
      font-family: $font-family-barlow;
      // background: $header-background;

      button {
        background-color: $color-primary;
        color: $white;
      }
    }

    .hk-elements--buyNow {
      button {
        color: $color-primary;
        background-color: $yellow_color;
        border-radius: 2px;
        font-family: $font-family-barlow;
      }
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 6px;

      .hk-elements--addToCart {
        margin-right: 8px;
      }

      .hk-elements--addToCart button,
      .hk-elements--buyNow button {
        font-size: 14px;
        padding: 10px 8px;
        width: auto;
        border-radius: 4px;
        height: 39px;
        text-transform: capitalize;
      }
    }
  }

  @media screen and (max-width: 400px) {

    .hk-elements--addToCart button,
    .hk-elements--buyNow button {
      font-size: 12px;
    }
  }
}

.tbs-pdp-stickey-bottom-atc {
  width: 100%;
  display: block;
  height: 70px;
  position: fixed;
  background: #fff;
  bottom: 0;
  left: 0;
  z-index: 999;
  padding: 10px 15px;
  animation: atc-bottom-slide-up 0.5s linear;

  .action-button-container {
    margin-top: 0rem;
  }

  .freebie-offer {
    display: none;
  }

  .actual-price {
    display: none;
  }

  .price-container {
    margin-top: 0;

    .percent-off {
      display: none;
    }
  }

  .mb-cash-info {
    display: none;
  }

  .pos-abs {
    position: absolute;
  }
}

.flt-left {
  float: left;
}

.flt-right {
  float: right;
}

.js-remove-pt {
  margin-right: 15px;
}

.order-summary-widget.for-checkout {
  padding: 0 15px;
}

.order-datail {
  display: flex;
  justify-content: space-between;

  .col-sm-6 {
    padding: 0px;
  }

  &.strickethrough {

    .mb-cash-text,
    .cash {
      text-decoration: line-through;
    }

    .link-btn {
      color: grey !important;
      cursor: initial;
    }
  }
}

.cart-inner {
  padding: 0 15px
}

.mb-cash-text {
  padding-left: 10px;
}

.mb-cash-prepaid {
  padding-top: 10px;
  border-top: 1px dashed #d3d4d5 !important;
}

.mb-cache-value {
  color: $black2;
  font-family: $fontRoboto;
  float: left;
  width: 100%;

  .singleLoyaltyCash {
    color: $orange4;
    font-weight: $bold;
    font-family: $fontRoboto;
  }
}

.amount {
  color: $orange4;
  font-weight: $bold;
  font-family: $fontRoboto;
  margin-left: 4px;
}

.hk-loader-default {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  margin: auto;
  z-index: 999;

  div {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: 2px solid #00bfbf;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    @include animation(loader_default 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8));
  }
}

@include keyframe(loader_default) {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

.style-normal {
  font-style: normal;
}

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }

  .hk-header-wrapper {
    min-height: auto;
    // z-index: 99999;

    // padding-top: 56px;
    .checkout-status.mobile {
      text-align: center;
      background: #f7f7f7;
      padding: 20px 0 20px;
      margin-top: 56px;

      span {
        text-transform: uppercase;
        color: #797979;
        font-size: 12px;

        &.active {
          color: #006cd6;
          font-weight: 700;
        }
      }
    }
  }

  .sing-line-header {
    position: fixed;
    top: 0;
    display: block;
    width: 100%;
    z-index: 999;
    box-shadow: 0 0 6px 0 #00000015;
    padding: 0 15px;
    background: #fff;
    border: none;
    height: 55px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    @include material-box-shadow(0, 0, 6px, rgba(0, 0, 0, 0.8));

    .mob-sl-header-back {
      width: auto;
      float: left;
      position: absolute;
      left: 15px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      line-height: 0;
    }

    .singleLineHeader__heading {
      color: #263238;
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
    }
  }
}

@media screen and (min-width: 1024px) {
  .single-line-header {
    min-height: $header-height-desktop;
  }

  .main-header-wrapper .header-top.minHeight {
    min-height: $header-height-desktop;
  }

  .tbs-header-logo {
    display: inline-block;
    margin-top: 13px;

    &.footer-logo {
      svg {
        height: 35px;
      }
    }

    img {
      height: 35px;
    }
  }

  .tbs-btn {
    max-width: 260px;
    margin: 0 auto;
    float: none;
  }
}

.hco-footer-content {
  p {
    font-size: 14px;
    line-height: 26px;
    color: #25292b;
    font-weight: 400;
  }
}

.err-txt,
.invld-code-err {
  font-size: 13px !important;
  color: red !important;
  line-height: 1.2em !important;
  margin-bottom: 5px;
  margin-top: 5px;
  text-transform: capitalize !important;
}

.success-msg-green {
  color: $green-2;
}

.bold {
  font-weight: bold !important;
}

.semiBold {
  font-weight: $semibold !important;
}

.font-roboto,
.price-text,
.reward-points-lft {
  font-family: $fontRoboto;

  &.bold {
    font-weight: $bold;
  }

  &.semiBold {
    font-weight: $semibold;
  }
}

.link-btn {
  font-size: 15px;
  color: #898989;
  border: 0px;
  background: transparent;
  white-space: nowrap;
  font-weight: $medium !important;
  cursor: pointer;

  &:hover {
    color: #898989;
  }

  &.orange {
    color: $orange1 !important;

    &:hover {
      color: $orange1;
    }
  }

  &.black2 {
    color: $black2 !important;

    &:hover {
      color: $black2;
    }
  }
}

.express-checkout {
  float: left;
  width: 100%;

  .order-summary-widget-wrapper {
    background: #f8f8f8;
    float: left;
    width: 100%;
  }

  .order-summary-widget {
    float: left;
    width: 100%;
    background: $white;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.static-accordion-container {
  .accordion-title {
    padding: 10px 0;
    border-bottom: 1px #ccc solid;

    &:last-child {
      border-bottom: 0;
    }

    .heading {
      color: #030303;
      font-size: 21px;
    }
  }

  .toggleIcon {
    display: flex;
    justify-content: space-between;

    .toggle-icon {
      margin-left: 20px;

      .opend {
        display: none;
      }

      .closed {
        display: inline-block;
      }
    }

    &.open {
      .opend {
        display: inline-block;
      }

      .closed {
        display: none;
      }
    }
  }

  .accordioin-text {
    display: none;
    padding-top: 20px;

    &.open {
      display: block;
    }
  }
}

.partner-banner {
  position: relative;
  float: left;
  width: 100%;
  background: url(https://static1.hkrtcdn.com/gritzo/static/media/misc/partner-banner.png) center center no-repeat;
  background-size: cover;
  margin: 0 0 50px 0;

  img {
    max-width: 100%;
    vertical-align: middle;
  }

  h1,
  h2 {
    font-size: 52px;
    color: $white;
    font-weight: $medium;
    line-height: normal;
  }

  h1 {
    margin: 0;
  }

  p {
    font-size: 26px;
    color: $white;
    line-height: normal;
  }

  .partner-content {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    background-size: cover;
    padding: 50px 0;
    position: relative;

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
      pointer-events: none;
      background: url(https://static1.hkrtcdn.com/gritzo/static/media/misc/partner-banner-overlay.svg) center center no-repeat;
      background-size: cover;
    }

    &>div {
      position: relative;
      z-index: 2;
    }
  }
}

.delivery-date-row {
  margin-top: 16px;
  float: left;
  width: 100%;

  .small {
    font-size: 12px !important;
  }

  .date {
    color: #808080;
  }

  .nt-avail-msg small {
    color: #800000;
  }

  .cod-text.cart {
    .red-txt {
      color: #800000;
    }

    .green-txt {
      color: $green-2;
    }

    .green-txt,
    .red-txt {
      display: flex;
      align-items: flex-start;
      font-size: 12px !important;

      img {
        margin-right: 5px;
        margin-top: 5px;
        vertical-align: middle;
        height: 14px;
      }
    }
  }
}

.proceed-to-payment {
  width: 100% !important;
}

.proceed-to-payment.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.hidden {
  display: none !important;
}

.small {
  font-size: 12px !important;
}

.delivery-days,
.small {
  font-size: 12px;
  color: #707070;
  line-height: 14px;
  margin-top: 8px;
}

.delivery-days {
  float: left;
  width: 100%;
}

.mb-cash-value {
  font-weight: $bold;
  color: $orange4;
  font-family: $fontRoboto;
}

h1 {
  .mb-cash-value {
    font-size: inherit;
  }
}

.mb-cash-mob {
  height: 30px;
}

.font-medium {
  font-weight: $medium;
}

.word-break {
  word-break: break-all;
}

.html-element * {
  font-family: $Poppins !important;
}

.video-popup-wrapper {
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;

  .video-popupinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 7px;
    width: 60%;
    height: 67%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 1023px) {
    .video-popupinner {
      width: 90%;
      height: 50%;
    }
  }

  &.productVariantsListModal {
    .close-button {
      top: 4px;
      right: 7px;
      right: 0px;
    }
  }
}

.no-event {
  pointer-events: none;
}

.font-13 {
  font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.owl-prev {
  @include owl-nav();
  background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/arrow-left-dark.svg) !important;
  left: -30px;
}

.owl-next {
  @include owl-nav();
  background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/arrow-right-2.svg) !important;
  right: -30px;
}

.owl-dots span {
  width: 8px !important;
  height: 8px !important;
  margin: 0 5px !important;
  border: 1px solid #d3d4d5;
  display: block;
  -webkit-backface-visibility: visible;
  @include transition(opacity 0.2s ease);
  border-radius: 30px;
  box-sizing: border-box;
  background: #fff !important;
}

.owl-dot.active {
  span {
    background: $primary-color !important;
    border-color: $primary-color;
  }
}

.owl-dots {
  position: static;
}

.owl-nav {
  margin: 0 !important;
}

.icn-mandatory {
  width: 8px;
  height: 8px;
  padding: 0 13px 0 0;
  color: red;
  font-weight: 700;
}

.pforild-form-label {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .edit-link {
    font-size: 14px;
    color: $black2;
    line-height: normal;
    font-weight: $semibold;
  }
}

.green-txt {
  color: $green-2;
}

.black2-text {
  color: $black2;
}

.add-address-content.nutritional {
  p {
    img {
      width: 100%;
      max-width: 100% !important;
    }
  }
}

.seo-sec {
  padding: 0 15px;
  display: block;
  width: 100%;
  float: left;
  text-align: left;
  position: relative;
  .seo_content_inline{
    display: none;
    &.open{
      display: block;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0px 16px;
    margin-bottom: 0px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    padding-left: 25px;

    // list-style: disc;
    li {
      margin-bottom: 0;
      padding-left: 0px;
      font-family: $fontFamily-regular;
      color: $header-background;
      line-height: 32px;
      font-size: 20px !important;

      @media screen and (max-width: 768px) {
        font-size: 15px !important;
        line-height: 20px !important;
      }
    }
  }

  span {
    @media screen and (max-width: 1024px) {
      padding: 16px 0px !important;
      text-align: left;
      font-size: 16px;
      line-height: 20px;
      margin: 0.67em 0;

    }
  }

  h1 {
    // margin: 5px 0 40px;
    padding: 15px;
    font-size: 30px;
    //font-size: 40px;
    line-height: 40px;
    color: $black;
    text-align: left;
    font-weight: $medium;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: $font-family-barlow;
    cursor: pointer;
    background-color: #f8f8f8;

    @media screen and (max-width: 1024px) {
      padding: 0px;
      text-align: left;
      font-size: 15px;
      line-height: 20px;
      display: flex;

    }

    * {
      font-size: inherit;
      font-weight: inherit;
    }
  }

  h1:after {
    // content: "";
    //  position: absolute;
    // position: relative;
    // width: 120px;
    // height: 3px;
    // @media screen and (max-width: 1024px) {
    //   background: transparent;
    // }
    // background: linear-gradient(
    //   to right,
    //   #001d42 65%,
    //   transparent 65%,
    //   transparent 75%,
    //   grey 75%,
    //   grey 100%
    // );
    left: 50%;
    margin-top: 1%;
    transform: translateX(-50%);

    @media screen and (max-width: 768px) {
      position: relative;
      bottom: -11px;
    }
  }

  h2,
  h3 {
    //margin: 5px 0 10px;
    padding-top: 15px;
    // font-size: 30px;
    //line-height: normal;
    //color: black;
    text-align: left;
    font-weight: $semibold;

    font-family: $font-family-barlow;
    color: $header-background;
    line-height: 32px;
    font-size: 20px !important;
    margin: 5px 0 0px;

    * {
      font-size: inherit;
      font-weight: inherit;
    }

    @media screen and (max-width:768px) {
      font-size: 13px !important;
      line-height: 20px !important;
      font-weight: bold;
    }
  }

  p {
    //  font-size: 14px;
    // line-height: 26px;
    //color: $black2;
    font-weight: 400;
    text-align: left;
    font-size: 20px;
    line-height: 32px;
    color: $header-background;
    margin: 10px 0px 10px 0px;
    font-family: $font-family-barlow;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  p+ul>li {
    font-size: 17px !important;
    font-family: $font-family-barlow;
  }

  strong {
    font-weight: bold;
    font-size: 17px;
    font-family: $font-family-barlow;
  }

  a {
    //color: #091D42;
    font-weight: 400;
    text-align: left;
    font-size: 20px;
    line-height: 32px;
    color: #20abe1;
    font-family: $font-family-barlow;
    text-decoration: underline;

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }

    * {
      color: $header-background;
    }

    &:hover {
      color: $sec-link;
      text-decoration: underline;
    }
  }

  br {
    content: '';
    display: block;
    height: 1px;
    margin: 2px 0px;
    padding: 0px;
    line-height: 0px;
  }
}

.form-wrapper {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.inline-block {
  display: inline-block;
}

.mobile-view {
  .accordion-wrap .accordion-item {
    margin-bottom: 10px;
  }

  .top-strip-wrap {
    padding: 30px 15px;
  }

  .radioFilled,
  .radioEmpty {
    width: 14px;
    height: 14px;
    display: inline-block;
    background-size: 100% auto;

    &:before {
      width: 6px;
      height: 6px;
    }
  }

  .seo-sec {
    margin-bottom: 30px;

    ul,
    h1 {
      font-size: 20px;
      line-height: normal;
      position: relative;
      padding-bottom: 12px;
    }

    h2,
    h3 {
      font-size: 18px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
    }

    br {
      content: '';
      display: block;
      height: 1px;
      margin: 2px 0px;
      padding: 0px;
      line-height: 0px;
    }
  }

  .yousave-checkout {
    background: $grey-15 !important;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
  }

  .shipping-addresss {
    .yousave-checkout {
      background: $grey-15 !important;
      font-size: 12px;
      text-align: center;
      margin-bottom: 20px;
    }

    .cart-layout {
      background: white;
      padding-bottom: 0px;
    }

    .tbs-cart-page-section {
      margin-bottom: 0px !important;
      border-bottom: 0px !important;
    }
  }

  .accordion-wrap .accordion-item {
    .accordion-heading {
      padding-left: 45px !important;

      .accordion-action-btn {
        background: transparent;
        color: $black2;
        border: 0;
        padding: 0;
        border-radius: 0;
        text-decoration: underline;
        font-size: 14px;
      }

      .bullet {
        left: 15px;
      }
    }
  }
}

.payment-failed-popup {
  padding: 15px;
  width: 90%;
  margin: 0px auto;

  img {
    max-width: 200px;
  }
}

.pmt-status-cntnr-resp {
  padding: 15px;
}

.order-confirm-btn {
  padding: 15px 0;
}

.tbs-login-signup-details {
  position: relative;

  .tbs-login-heading span.fr.inline-block.cursor-pointer {
    position: absolute;
    right: 0;
    top: 0;
  }
}

#resendOTPText {
  margin-bottom: 0px !important;
  margin-top: 0px;
  font-size: 13px;
  color: #0c9600;
}

@media screen and (max-width: 991px) {
  iframe.xdk-iframe {
    bottom: 75px !important;
    right: 2px !important;
  }
}

.no-chat-button {
  iframe.xdk-iframe {
    visibility: hidden !important;
    opacity: 0;
  }
}

.sc-pay-cod-mobile {
  .sc-pay-payment-page {
    margin: 20px 0 65px 0 !important;
  }
}

.breadcrumb-content {
  a {
    font-size: 12px;
    line-height: 16px;
    color: #969798;
  }

  span {
    font-size: 12px;
    line-height: 16px;
    color: #525455;
    font-weight: $bold;
  }
}

.space-between {
  justify-content: space-between;
}

.tbs-header-section-right {
  align-items: center;

  img {
    height: 25px;
    margin-right: 5px;
  }
}

body.no-scrolling {
  // overflow: hidden;
  overflow: auto;
}

.hk-loader-default {
  position: fixed;
  background: rgb(238, 238, 238);
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.reactApplication input:-webkit-autofill {
  background-color: rgba(116, 116, 128, 0.0784313725490196) !important;
  -webkit-box-shadow: 0 0 0 1000px #f4f4f4 inset !important;
  -webkit-text-fill-color: #000 !important;
}

.reactApplication input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #f4f4f5 inset !important;
  -webkit-text-fill-color: #333;
}

#Additional-Information {
  .pdp-accordion__body {
    padding: 20px 0 28px;
  }
}

.Additional-Information {
  width: 100%;

  .pdp-accordion__body {
    padding: 20px 0 25px;

    .handle-cta {
      font-weight: 600;
      background-color: #FFF;
      font-size: 13px;
      color: #262d33;
      padding: 10px 15px;
      border: 1px solid #d9dadb;
      border-radius: 20px;
      display: inline-block;
      // width: 112px;
      // height: 37px;
      cursor: pointer;
      margin-top: 30px;
      text-align: center;
      margin-left: 115px;

      &:hover {
        color: #fff;
        border: 1px solid #262d33;
        background-color: #262d33;
        transition: all .5s ease;
      }
    }
  }
}

.promo_skel {
  background-color: #f2f2f2;
  height: 46px;
  display: flex;
}

.mweb-pdp-img {
  min-height: 370px;
  text-align: center;

  .js-img-thumb {
    img {
      width: 100%;
    }
  }
}

.mweb-pdp-image-cont {
  min-height: 228px;
  position: relative;
}

.pdp-accordion__virtual {
  display: none;
}

.common-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: rgba(0, 0, 0, .5);
  // display: none;

  .common-modal-body {
    position: absolute;
    width: 100%;
    max-height: 90%;
    background: white;
    min-height: 200px;
    height: 100%;
    border: 1px solid #ebebeb;
  }

  &.web {}

  &.mweb {
    .common-modal-body {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      max-height: 90%;
      background: white;
      min-height: 200px;
      height: 100%;
      border: 1px solid #ebebeb;
    }
  }

  &.center .common-modal-body {
    left: 50%;
    top: 50%;
    max-width: 80%;
    max-height: 90%;
    background: white;
    min-height: 200px;
    transform: translate(-50%, -50%);
  }

  &.darkBG {
    background-color: rgba(38, 38, 38, 0.9) !important;
  }

  .common-modal-body-props-classWidthChange {
    max-width : 30% !important;
    min-height: fit-content !important;
    max-height: fit-content !important;
    height    : auto !important;
    border    : none !important;
  }

  .common-modal-body-props-classWidthChange-mweb {
    max-width : 80% !important;
    min-height: fit-content !important;
    max-height: fit-content !important;
    height    : auto !important;
    border    : none !important;
  }

  .common-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    justify-content: space-between;

    .heading-cn {
      background: white;
      font-weight: 600;
      padding: 10px 0px;
      font-size: 20px;
      color: #212121;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ebebeb;
      padding: 20px;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .body-cn {
      background: white;
      height: 4000px;
      max-height: 100%;
      flex-grow: 1;
      overflow: auto;
      padding: 20px;
    }

    .footer-cn {
      padding: 20px;

    }
  }

  // @media screen and (max-width: 1024px) {

  //   Appbar
  .open-appbar {
    position: fixed;
    bottom: 65px;
    left: 0;
    z-index: -1 !important;
    width: 100%;
    border-top: 2px solid #8AD1CF;
    background-color: #F5FDFF;
    padding: 9px 16px;

    .appbar {
      // @include font(14px, 600, 22px, 0.22px, #18BFBF);
      @include fontStyle($fontFamily-oswald, 12px, 500, 14px, 0px, #18BFBF);

      text-transform: capitalize;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .close-appbar {
      cursor: pointer;
      float: right;
      margin: 0;
    }
  }
}

// }

.hm-swpr-dsk-cnt-small {
  left: 0;
  bottom: 0;
}

.delivery-info-fixed-cont {
  min-height: 66px;
}

// Home banner pagination
.swiper-pagination-bullets {
  bottom: 20px;

  .swiper-pagination-bullet-active {
    background-color: #21212E;
    height: 14px;
    width: 14px;
  }

  .swiper-pagination-bullet {
    height: 14px;
    width: 14px;
  }
}

.menu-border-bottom {
  margin-right: 0px !important;
  height: 0px !important;
  padding: 0px !important;
  margin-left: 54px;

}

.menu-border-bottom,
.border-bottom {
  border-bottom: 1px solid #DBDDE8 !important;
  width: 100%;
  opacity: 0.8;
  margin-top: 10px;
}

.address-payment-page {
  padding: 20px 15px;
  border-top: 1px #D5D5D5 solid;
  border-bottom: 1px #D5D5D5 solid;
  margin-bottom: 10px;
  background: $white;

  .checkoutheadings {
    font-size: 15px;
    font-weight: $medium;
    color: #000000;
    line-height: 35px;
  }

  .delivery-detail-wrap {
    display: flex;
    align-items: flex-start;

    .left-cont {
      width: 100%;
    }

    .link-btn {
      margin: 0 0 0 20px;
    }
  }
}

.usrdetail {
  span {
    color: $black21;
    font-size: 14px;
    line-height: normal;
    display: inline-block;
    margin-left: 10px;
    font-weight: $medium;
    text-transform: capitalize;

    &:first-child {
      margin-left: 0px;
    }
  }
}


.VPMw .hk-variants-cta .hk-elements--addToCart button {
  background: #FFBE00;
  color: #20262E;
  min-width: 156px;

  @media(max-width: 1023px) {
    min-width: auto;
  }

  // border: 1px solid #161616;
}

.search-v4-grouping-data {
  .hk-swiper {
    position: relative;

    // padding-left: 10px;
    .swiper-button-prev,
    .swiper-button-next {
      background-repeat: no-repeat !important;
      max-width: 100%;
      max-height: 100%;
      border: none;
      box-shadow: none;
      // background-position: 100%!important;
      background-size: contain;
      transform: none;
      border-radius: 0;
      top: 53%;
      z-index: 2;

      &::after {
        display: none;
      }

      &.swiper-button-disabled {
        opacity: 0.1;
      }
    }

    .swiper-button-disabled {
      pointer-events: auto !important;

      &:hover {
        cursor: not-allowed !important;
      }
    }

    .swiper-button-prev {
      background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/grey_arrow_left.svg) !important;
      left: -13px !important;

      @media screen and (min-width:1240px) {
        left: -25px !important;
      }
    }

    .swiper-button-next {
      background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/grey_arrow_right.svg) !important;
      right: -15px !important;
    }

    .BS-Desk {
      margin: 0 25px 0 10px;
      position: unset;

      .swiper-wrapper {
        // padding: 0 10px;
        margin: 0 -10px;

        .swiper-slide {
          // border:2px solid orange;
          padding: 0 0px 0px 10px;
          margin-right: 6px !important;

          .popular-product {
            .hk-elements--addToCart {
              padding-top: 10px;

              button {
                border: none;
                outline: none;
                background: #080A08;
                border-radius: 2px;
                height: 31px;
                font-family: "Roboto";
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0px;
                color: #FFFFFF;
                min-width: 100px;
                border-radius: 2px;
              }
            }
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.ODI__cancelled-list {
  .order__item {
    width: 100%;
    column-gap: 19px;

    @media(max-width: 767px) {
      column-gap: 10px;
    }

    img {
      min-width: 116px;
      max-width: 116px;
      height: 116px;
      border-radius: 5px;

      @media(max-width: 767px) {
        min-width: 15px;
        max-width: 58px;
        height: 58px;
      }
    }

    .details {
      p {
        display: flex;
        flex-direction: column;
        
        &.price {
          @include fontStyle($fontFamily-default, 24px, 500, 31px, 0px, $color-primary);
          margin-top: 5px;
          text-transform: uppercase;

          @media(max-width: 767px) {
            margin: 0;
            @include fontStyle($fontFamily-default, 14px, 400, 22px, 0px, $color-primary);
          }
        }
      }
    }
  }
}

.Shop-Flav-D {
  .swiper-container {
    position: unset;

    .swiper-button-prev,
    .swiper-button-next {
      width: 38px;
      height: 38px;
    }
  }
}

.free-cod-desktop {
  margin-top: 15px;
  .free-cod-card {
    position: unset;
    border-radius: 6px;
    box-shadow: none;
    background: #F7F8F9;

    .content {
      border-bottom: none;

      .img-cont {
        img {
          height: 28px;
          width: 23px;
          margin-top: -3px;
        }
      }

      .text {
        @include fontStyle($font-family-barlow, 14px, 400, 18px, 0, #080a08);

        span {
          @include fontStyle($font-family-barlow, 14px, 400, 18px, 0, #080a08);
        }
      }

      .add-more-btn {
        a {
          @include fontStyle($font-family-barlow, 14px, 400, 18px, 0, #fff);
        }
      }
    }

    &.range-card {
      padding: 0;

      .range .content {
        margin-top: 8px;
      }
    }
  }

  &.type-address {
    .free-cod-card {
      .content {
        height: fit-content;
        padding: 5%;

        .add-more-btn {
          a {
            padding: 6px 8px;
          }
        }
      }
    }
  }
}

.load-more-btn-cat {
  a {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    color: #898989;
    width: fit-content;
    margin: 0 auto;
    display: block;
    text-decoration: underline;
    background-color: transparent;
    border: 0;
  }
}


.gymtrainer-container {
  .select-search-container {
    width: 100%;
  }

  .select-search-value {
    width: 100%;
  }

  .select-search-input {
    width: 100%;
    height: 45px;
    background: #F8F8F8;
    border-radius: 2px;
    border: #F8F8F8;
    text-indent: 20px;
    margin-top: 8px;
    padding: 0px;
    font-size: 14px;
    color: #070907
  }

  .select-search-option,
  .select-search-not-found {
    font-size: 14px;
    height: 30px;
  }
}

.gritzo-india {
  .main-card-container {
    @media screen and (max-width:1023px) {
      .hk-swiper {
        .swiper-container {
          .swiper-button-prev {
            left: -27px !important;
            top: 59%;
          }

          .swiper-button-next {
            top: 59%;
            right: -27px !important;
          }

          .swiper-slide {
            .img-card-conta {
              img {
                box-shadow: unset;
              }
            }
          }
        }
      }

    }

    @media screen and (min-width:1024px) {

      .hk-swiper {
        .swiper-container {
          .swiper-slide {
            width: 240px !important;

            .img-card-conta {
              img {
                width: 100% !important;
                box-shadow: unset;
              }
            }
          }
        }
      }
    }
  }
}

.videos-ps-component {
  .videos-ps-inner {
    width: 80%;
    margin: 0 auto;
    max-height: 340px;

    .video-section-container {
      border-radius: 24px;
      box-shadow: 0px 0px 30.515033721923828px 0px #00000066;

      .swiper-pagination {
        bottom: -36px;

      }

      .swiper-pagination-bullet {
        width: 8px;
        height: 6px;
        background-color: #282960;
      }

      .swiper-pagination-bullet-active {
        width: 30px;
        height: 6px;
        border-radius: 44px;
      }
    }
  }
}

@media (max-width: 991px) {
  .verloop-button {
    bottom: 70px !important;
  }
}
.no-chat-button {
  iframe.xdk-iframe {
    visibility: hidden !important;
    opacity   : 0;
  }
  .verloop-button {
    visibility: hidden !important;
    opacity   : 0;
  }
}

@media screen and (max-width: 1024px) {

  //   Appbar
  .open-appbar-new {
    position: fixed;
    bottom: 45px;
    left: 57%;
    z-index: 3;
    width: 100%;
    background: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60px;
    border-radius: 10px;
    width: 49%;
    /* height: 200px; */
    margin: 0 auto;
    padding: 10px;
    left: 0%;


    .appbar-new {
      // @include font(14px, 600, 22px, 0.22px, #18BFBF);
      @include fontStyle($fontFamily-oswald, 12px, 500, 14px, 0px, #FFF);

      text-transform: capitalize;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #002140;
      margin: 4px 0px;
      padding: 14px;
     height: 63px;
    min-width: 63px;
    margin: 4px 6px;
    border-radius: 100%;
    }

    .close-appbar {
      cursor: pointer;
    float: right;
    margin: 0;
    right: 32%;
    top: 2px;
    position: relative;
    }

    .icon {

      img {
        margin: 0px 10px;
        background-color: #fff;
        border-radius: 26px;
      }
    }

    .open-app-content-new {
      display: flex;
      justify-content: space-between;

      .icon {}

      .open-app-text-new {
        padding: 0px 11px;

        .top-text-new {
          @include fontStyle($fontFamily-oswald, 12px, 600, 18px, 0px, #FFFFFF);
        }

        .bottom-text {
          @include fontStyle($fontFamily-oswald, 12px, 400, 20px, 0px, #FFFFFF);
        }
      }
    }
  }
}
.Home-slider-refral{
  width: 100%;
  .swiper-slide-active{
    @media screen and (min-width: 1024px) {
      width: 100% !important;
    }
  }
}

.ordr_trkng {
  min-height: auto !important;
}