/*
==========================================================================================
                        FONT VARIABLES
==========================================================================================
*/

// ======= font family
$font-family-barlow : 'Barlow', sans-serif;
$Poppins: "Barlow", sans-serif;
$fontFamily-default: 'Barlow', sans-serif;
$fontFamily-oswald: 'Barlow', sans-serif;
$fontFamily-bold: 'Barlow', sans-serif;
$fontFamily-regular: 'Barlow', sans-serif;


// ======= font size
$fontSize-default: 14px;
$fontSize-default2: 14px;
$fontSize-headingSmall1: 18px;
$fontSize-headingSmall2: 16px;
$fontSize-headingSmall3: 14px;
$fontSize-headingSmall4: 12px;
$fontSize-headingSmall5: 11px;
$fontSize-headingSmall6: 10px;

/* image  locatins */
$icons:'/static/media/icons/';
/* font weight */
$light:300;
$regular:400;
$medium:500;
$semibold:600;
$bold:700;
$fontRoboto:'Barlow', sans-serif;
$fontPoppins: 'Barlow', sans-serif;
/*
==========================================================================================
                        COLORS VARIABLES
==========================================================================================

/* MB revamp new Variables */
$color-white: #FFFFFF;
// $color-primary: #001D42;
$color-primary: #002140;
$color-secondary: #FFD800;
/* end MB revamp new Variables */

/* GREEN COLORS */
$green: #02B74D;
$green-1:#3BC56A;
$green-2: #006A39;

/* BLUE  COLORS */
$blue: #21a0b0;
$tpz-blue: #00B8E3;
$blue-1:#0D0039;
$light-blue: #FAF6E7;
$blue-light: #E9F8FC;
$blue-2: #00bfbf;
$blue-3: #1a4f69;
$blue4: #364045;
$sec-link:#337ab7;
$blue5: #20ABE1;
$text-blue:#0085CB;
/* BLACK COLORS */
$black: #000;
$black1: #141414;
$black2: #393939;
$black3: #313131;
$black4: #2E2E2E;
$black5: #434141;
$black6: #1F1F1F;
$black7: #1A1A1A;
$black8: #030303;
$black9: #00000014;
$black10: #413737;
$black11: #00141C;
$black12: #2E2F30;
$black13: #0D0039;
$black14: #2B2B2B;
$black15: #212121;
$black16: #0A0000;
$black17: #515455;
$black18: #C4C4C4;
$black19: #262626;
$black20: #242424;
$black21: #272727;

/* ORANGE COLORS */
$orange: #FF7E00;
$orange1: #FE7E10;
$orange2: #FBB03B;
$orange3: #FF6C00;
$orange4: #FF5700;
$orange5: #FF5127;
$orange6: #ED4000;
$orange7: #FE4710;
$orange8: #df4622;
$orange9: #FB8C00;

/* YELLOW COLORS */
$yellow: #F7C434;
$yellow1: #FFC810;
$yellow2: #FEC710;
$yellow4: #FFCE08;
$yellow5: #FFC800;
$yellow6: #FCD800;
$yellow7: #feb614;
$yellow8: #FFD800;


/* GREY COLORS */
$grey-0:#F8F7F5;
$grey-1: #6A6A6A;
$grey-2: #DDDDDD;
$grey-3: #9B9B9B;
$grey-4: #525252;
$grey-5: #CCCCCC;
$grey-6: #B9B9B9;
$grey-7: #050505;
$grey-8: #D8D8D8;
$grey-9: #EEEEEE;
$grey-10: #707070;
$grey-10-rgba:'112,112,112';
$grey-11: #EBEBEB;
$grey-12: #AEAEAE;
$grey-13: #6E6E6E;
$grey-14: #d3d4d5;
$grey-15: #F8F8F8;
$grey-16: #898989;
$grey-17: #363232;
$grey-18: #5D5D5D;
$grey-19: #808080;
$grey-20: #121212;
$grey-22: #646464;
$grey-23: #4B4B4B;
$grey-24: #070707;
$grey-25: #EFEFEF;
$grey-26: #70707021;
$grey-27: #F1F1F1;
$grey-28: #F2F0ED;
$grey-29: #D9D9D9;
$grey-30: #FAF9F8;
$grey-31: #00000029;
$grey-32: #E8E8E8;
$grey-33: #EDE8E8;
$grey-34: #100e09;
$grey-35: #0000002C;
$grey-36: #F0F0F0;
$grey-37: #ADADAD;
$grey-38: #F3F3F3;
$grey-39: #F5F5F5;
$grey-40: #D3D3D3;
$grey-41: #4D4D4D;
$grey-42: #414141;
$grey-43: #e9e9e9;
$grey-44: #ceebef;
$grey-45: #F7F7F7;
$grey-46: #D5D5D5;
$grey-47: #B1A9A9;
$grey-48: #CECECE;
$grey-49: #2F2F2F;
$hero-grey: #F2F4F5;
$grey-50: #F4F4F4;
$grey-51: #4a4a4a;
$grey-52: #7c7f80;
$grey-53: #95A0AF;

/* OTHER COLORS */
$default: #2C2C2C;
$red: #FF0000;
$error-color:#B00020;
$white: #fff;
$light-brown: #F8EECA;
$peach: #FBB03B5C;
$primary-color: #FF7E00;
$primary-color1: #FE7E10;
$primary-color2: #FF6C00;
$primary-color4:#FFBE00;

$primary-color3: #99adb0;
$brand-color: #FE7E10;
$gz-sky-blue:#18bbdf