@import 'static/css/global/global.scss';

.hk-home-container {
    .hk-swiper {
        position: relative;

        .swiper-button-prev,
        .swiper-button-next {
            background-repeat: no-repeat !important;
            max-width: 100%;
            max-height: 100%;
            border: none;
            box-shadow: none;
            // background-position: 100%!important;
            background-size: contain;
            transform: none;
            border-radius: 0;
            top: 53%;
            z-index: 2;

            &::after {
                display: none;
            }

            &.swiper-button-disabled {
                opacity: 0.1;
            }
        }

        .swiper-button-disabled {
            pointer-events: auto !important;

            &:hover {
                cursor: not-allowed !important;
            }
        }

        .swiper-button-prev {
            background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/slick-slider/slider1/prev_v1_new.svg) !important;
            left: -43px !important;

            // @media screen and (min-width:1240px) {
            //     left: -50px !important;
            // }
        }

        .swiper-button-next {
            background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/slick-slider/slider1/next_v1_new.svg) !important;
            right: -15px !important;

            @media screen and (min-width: 1024px) {
                right: -19px !important;

            }
        }

        .goal-section-container {
            background-color: transparent !important;
        }

        .BS-Desk {
            // margin: 0 25px 0 0px;
            position: unset;

            @media screen and (min-width: 1024px) {
                padding: 6px 0px;
            }

            .swiper-wrapper {

                // padding: 0 10px;
                // margin: 0 -10px;
                .swiper-slide {

                    // padding: 0 10px;
                    .popular-product {
                        .hk-elements--addToCart {
                            padding-top: 10px;

                            .add-to-cart-button-forword-icon {
                                height: 13px;
                                width: 8px;
                                margin-left: 17px;

                                @media screen and (max-width: 1024px) {
                                    margin-left: 8.60px;
                                    height: 10.8px;
                                    width: 6.3px;
                                }
                            }

                            button {
                                border: none;
                                outline: none;
                                border-radius: 2px;
                                font-family: "Roboto";
                                font-weight: 500;
                                letter-spacing: 0px;
                                border-radius: 2px;
                                padding: 11px 0px;
                                width: 100%;
                                background-color: #FFBE00;
                                color: #20262E;
                                font-size: 16px;
                                line-height: 14px;
                                text-transform: uppercase;
                                margin-top: 25px;
                                align-items: center;
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                width: 24px;
                height: 24px;

                @media screen and (min-width: 1024px) {
                    width: 38px;
                    height: 38px;
                    top: 50%;
                }
            }
        }

        .BS-Mob {

            .goal-section-product {
                .hk-elements--addToCart {
                    @media screen and (max-width: 1024px) {

                        padding-bottom: 2px !important;
                    }
                }
            }

            .popular-product {
                @media(max-width:1024px) {
                    height: 140px;
                    padding: 6px;
                    margin: 0px 6px;

                    @media screen and (max-width: 1024px) {
                        margin: 0px 8px 0px 0px;
                    }
                }

                .goal-section-card {
                    @media screen and (max-width: 1024px) {
                        padding-top: 10px;
                        padding-bottom: 1px !important;
                    }
                }

                .hk-elements--addToCart {
                    padding-top: 10px;

                    @media screen and (max-width: 1024px) {
                        padding-top: 10px;
                        padding-bottom: 8px;
                    }

                    .add-to-cart-button-forword-icon {
                        height: 13px;
                        width: 8px;
                        margin-left: 17px;

                        @media screen and (max-width: 1024px) {
                            margin-left: 8.60px;
                            height: 10.8px;
                            width: 6.3px;
                        }
                    }

                    button {
                        border: none;
                        outline: none;
                        background: #080A08;
                        border-radius: 2px;
                        height: 31px;
                        font-family: "Roboto";
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        min-width: 100px;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media screen and (max-width: 1024px) {
                            width: 100%;
                            background-color: #FFBE00;
                            color: #20262E;
                            font-size: 12px;
                            line-height: 14px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .Pop-Cat {
            margin: 0 15px;
            position: unset;

            @media screen and (max-width: 1024px) {
                margin: 0px;
            }

            .swiper-button-prev,
            .swiper-button-next {
                width: 20px;
                height: 20px;
                top: 58%;
            }

            .swiper-button-prev {
                left: -10px !important;

            }

            .swiper-button-next {
                right: -10px !important;
            }

            @media screen and (min-width:768px) {
                .swiper-button-disabled {
                    display: none;
                }
            }
        }

        .Shop-Flav-D {
            // padding: 0 25px;
            max-width: 1024px;
            margin: 0px 0px;

            @media screen and (min-width:992px) {
                .swiper-button-disabled {
                    display: none;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                width: 23.5px;
                height: 23.5px;
            }

            .swiper-button-prev {
                left: 2px !important;

            }

            .swiper-button-next {
                right: 2px !important;
            }
        }

        .home-slider-M {
            display: flex;
            flex-direction: column;

            .swiper-wrapper {
                order: 1;
            }

            .swiper-pagination-bullets {
                order: 2;
                bottom: 12px;

                .swiper-pagination-bullet {
                    zoom: 1;
                    background: #ffffff;
                    opacity: 0.2;
                    padding: 0 !important;
                    width: 6px;
                    height: 6px;
                    margin: 0 3px;

                    &.swiper-pagination-bullet-active {
                        opacity: 1;
                        width: 7px;
                        height: 7px;
                    }
                }
            }
        }

        .home-slider-D {
            // min-height: 512px;
            min-height: 300px;
            .swiper-button-prev,
            .swiper-button-next {
                width: 30px;
                height: 30px;
            }

            .swiper-button-prev {
                background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/leftArrow.svg) !important;
                width: 60px;
                height: 60px;
                background: #27274f80;
                border-radius: 50%;
                left: 30px !important;
                background-position: center;
            }

            .swiper-button-next {
                background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/rightArrow.svg) !important;
                width: 60px;
                height: 60px;
                background: #27274f80;
                border-radius: 50%;
                right: 30px !important;
                background-position: center;
            }

            // &::after {
            //     content: '';
            //     background-color: $yellow_color;
            //     width: 100%;
            //     height: 10px;
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     z-index: 9;
            // }
        }

        .home-slider-D2 {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 55%;
            margin-bottom: -3%;
            border-bottom: 4px solid $yellow_color;

            .swiper-slide {
                img {
                    cursor: pointer;
                }
            }
        }
    }

    .trending__container {
        .BS__container {
            padding: 0 0 34px;

            .title-wrap {
                display: none;
            }
        }
    }

    .shop-brand-section {
        .brand_list {
            .offscreenimage {
                height: 190px;
                width: 425px;

                @media screen and (max-width:768px) {
                    height: 60px;
                    width: 153px;
                }
            }
        }
    }

    .shop-flavours {
        &__container {
            .text {
                @media screen and (max-width:768p) {
                    font-size: 16px;
                }
            }

            .text_content {
                display: flex;
                justify-content: center;
                align-items: center;

                .range {
                    color: #001D42;
                    bottom: 30%;
                    font-size: 21px;
                    line-height: 32px;
                }
            }

            .flavour__img {
                width: 270px;

                @media screen and (max-width:768px) {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}
.buyAgain{
    
    .buyAgain-products {
        .hk-swiper {
           position: relative;
           z-index: 0;
    
           .swiper-button-prev,
           .swiper-button-next {
               background-repeat: no-repeat !important;
               max-width: 100%;
               max-height: 100%;
               border: none;
               box-shadow: none;
               background-size: contain;
               transform: none;
               border-radius: 0;
               top: 53%;
               z-index: 2;
    
               &::after {
                   display: none;
               }
    
               &.swiper-button-disabled {
                   display: none;
               }
           }
    
           .swiper-button-disabled {
               pointer-events: auto !important;
    
               &:hover {
                   cursor: not-allowed !important;
               }
           }
    
           .swiper-button-prev {
               background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/slick-slider/slider1/prev_v1_new.svg) !important;
               left: -43px !important;
           }
    
           .swiper-button-next {
               background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/slick-slider/slider1/next_v1_new.svg) !important;
               right: -15px !important;
    
               @media screen and (min-width: 1024px) {
                   right: -19px !important;
    
               }
           }
    
    
           .BS-Desk {
               position: unset;
    
               @media screen and (min-width: 1024px) {
                   padding: 6px 0px;
               }
               .swiper-button-prev,
               .swiper-button-next {
                   width: 24px;
                   height: 24px;
    
                   @media screen and (min-width: 1024px) {
                       width: 38px;
                       height: 38px;
                       top: 50%;
                   }
               }
           }
       }
    }
}
