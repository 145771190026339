@import 'static/css/global/global.scss';
.tbs-login {
    background-color: #fff;
    text-align: center;
    padding: 20px;
    width: 100%;
    float: left;
    .tbs-login-page-wrapper {
        max-width: 375px;
        // box-shadow: 0 3px 20px #00000029;
        text-align: left;
        position: relative;
        margin: 0px auto;
    }
}

.auth-heading {
    color: $black2;
    font-size: 23px;
    line-height: 75px;
    font-weight: $semibold;
}

.auth-hint {
    color: $black2;
    font-size: 13px;
    text-align: center;
    margin-bottom: 10px;

    a {
        color: $orange;
    }
}
.btn.btn-login {
    background: $black;
    color: $white;
    font-size: 16px;
    width: 100%;
    border: 1px $grey-5 solid;
}

.btn.btn-otp-req {
    @extend .btn-login;
    background: transparent;
    border: 2px $black2 solid;
    color: $black2!important;
}



.seprator-text {
    font-size: 14px;
    line-height: 32px;
    color: $black;
    clear: both;
}

.login-social-action {
    margin-top: 36px;
}

.login-social-box {
    text-align: center;

    .social-box-heading {
        color: $black2;
        font-weight: $semibold;
        font-size: 13px;
        line-height: normal;
        margin-bottom: 11px;
    }

    .social-box-links {
        display: flex;
        justify-content: space-between;
        .social-box-link {
            display: inline-block;
            padding: 15px 25px;
            border: 1px solid $grey-5;
            border-radius: 4px;
            height: 46px;
            text-align: center;
            position: relative;
            cursor: pointer;
            &:last-child{
                margin-right: 0;
            }
            img {
                position: relative;
                left: -5px;
                bottom: 3px;
                width: 24px;
                height: 24px;
            }

            .sl-name {
                position: relative;
                right: -5px;
                bottom: 10px;
                color: #000000;
            }
        }
    }
}

.form-control.forgot-pwd-input{
    padding-right: 67px;
}